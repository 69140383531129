import { remove, subscribeAll } from '../db'

const accountsCollectionName = 'accounts'
const collectionName = 'what-to-build-summaries'

const whatToBuildSummariesDelete = async (accountId, id) => {
  await remove(`${accountsCollectionName}/${accountId}/${collectionName}`, id)
}

const whatToBuildSummariesSubscribeAll = (accountId, mutationCallback) => subscribeAll(`${accountsCollectionName}/${accountId}/${collectionName}`, mutationCallback)

export {
  whatToBuildSummariesDelete,
  whatToBuildSummariesSubscribeAll
}
