import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { queryRun } from '../../api/query'
import ResultsTable from '../query/results-table'
import { useSelector } from 'react-redux'

const feelOptions = [
  'all', 'Very disappointed', 'Somewhat disappointed', 'Not disappointed'
]

const DeepDiveComponent = () => {
  const audienceAll = useSelector((state) => state.homeControl.audienceAll)
  const account = useSelector(state => state.accountReducers.account)
  const [loading, setLoading] = useState(false)
  const [feel, setFeel] = useState(false)
  const [audience, setAudience] = useState(false)
  const [results, setResults] = useState([])
  const [error, setError] = useState(null)

  const buildQuery = () => {
    const conditions = []

    if (feel && feel !== 'all') {
      conditions.push(`feel = '${feel}'`)
    }

    if (audience && audience !== 'all') {
      conditions.push(`audience = '${audience}'`)
    }

    const where = conditions.length > 0 ? `WHERE ${conditions.join(' AND ')}` : ''
    return `SELECT * FROM \`feedback\` ${where} ORDER BY date DESC LIMIT 100`
  }

  const runQuery = async () => {
    const query = buildQuery()

    setLoading(true)
    setError(null)

    const response = await queryRun(query, account.id)

    if (response.error) {
      setError(response.error)
    } else {
      setResults(response.results)
    }

    setLoading(false)
  }

  useEffect(() => {
    runQuery()
  }, [feel, audience])

  const setFeelHandle = (value) => {
    setFeel(value)
  }

  const setAudienceHandle = (value) => {
    setAudience(value)
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Raw data</h1>
        </Col>
      </Row>
      <Row className="py-4">
        <Col>
          <h4>Feel</h4>
          <select
            className='input-container__input'
            onChange={e => setFeelHandle(e.target.value)}
          >
            {feelOptions.map((option) => (
              <option
                value={option}
                key={option}
              >{option}</option>
            ))}
          </select>
        </Col>
        <Col>
          <h4>Audience</h4>
          <select
            className='input-container__input'
            onChange={e => setAudienceHandle(e.target.value)}
          >
            {['all', ...audienceAll].map((option) => (
              <option
                value={option}
                key={option}
              >{option}</option>
            ))}
          </select>
        </Col>
      </Row>

      {error &&
        <Row className="py-4 error">
          {error}
        </Row>
      }

      {loading &&
        <Row className="py-4">
          Loading...
        </Row>
      }

      {results.length > 0 &&
        <Row className="py-4">
          <ResultsTable results={results} />
        </Row>
      }

    </Container>
  )
}

export default DeepDiveComponent
