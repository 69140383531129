import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Badge, Button, Col, Container, Row, Table } from 'reactstrap'
import Loader from '../../components/Loader'
import { useSelector } from 'react-redux'
import { accountFormsSubscribe } from '../../api/accounts/forms'
import { Link } from 'react-router-dom'

const FormManagerList = () => {
  const navigate = useNavigate()
  const account = useSelector(state => state.accountReducers.account)
  const accountId = account.id

  const [loading, setLoading] = useState(true)
  const [forms, setForms] = useState([])

  useEffect(() => {
    const unsubscribe = accountFormsSubscribe(accountId, (templates) => {
      setForms(templates)
      setLoading(false)
      if (templates.length === 1) {
        navigate(`/dashboard/form-manager/edit/${templates[0].id}`)
      }
    })

    return unsubscribe
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Form Manager</h1>
        </Col>
        <Col></Col>
        <Col title="Please contact support to enable this feature for you.">
          <Button
            disabled={true}

            color='primary'
            onClick={() => navigate('/dashboard/form-manager/select-template')}
          >Create New Form</Button>
        </Col>
      </Row>
      <Row className="mt-5">
        {!loading &&
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Form name</th>
                <th></th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {forms.map((form, index) => (
                <tr key={`${form.id}-${form.metadata?.updatedAt.toDate() || '-'}`} scope="row">
                  <th>#{index + 1}</th>
                  <td onClick={() => navigate(`/dashboard/form-manager/edit/${form.id}`)}>{form.metadata.name || 'Unnamed'}</td>
                  <td>
                    {form.metadata.published && <Badge color='success'>Published</Badge>}
                  </td>
                  <td>
                    {!form.metadata.published && <Link
                      to={`/dashboard/form-manager/edit/${form.id}`}>Edit</Link>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
        {loading && <Loader></Loader>}
      </Row>
    </Container>
  )
}

export default FormManagerList
