import { functions, httpsCallable } from '../firebase'

const queryRun = async (query, accountId, debug = false) => {
  const call = httpsCallable(functions, 'queryGet')
  const { data } = await call({ query, accountId, debug })
  return data
}

const whatToBuild = async (query, accountId) => {
  const call = httpsCallable(functions, 'whatToBuild')
  const { data } = await call({ query, accountId })
  return data
}

export {
  queryRun,
  whatToBuild
}
