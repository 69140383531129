import React, { useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { t } from '../../../utils/translate'

const FormStepOptions = ({ form, options, onSelect, defaultValue }) => {
  const [selected, setSelected] = useState(defaultValue)

  return (
    <div className="options">
      {options.map((option, i) => (
        <Row key={i}>
          <Col>
            <Button
              style={{
                borderColor: form.metadata.colors.brand,
                backgroundColor: form.metadata.colors.brandLight,
                color: form.metadata.colors.brand
              }}
              className={`btn btn-primary ${option === selected ? 'option-selected' : ''}`}
              onClick={() => {
                onSelect(option)
                setSelected(option)
              }}
            >{t(option)}</Button>
          </Col>
        </Row>
      ))}
    </div>
  )
}

export default FormStepOptions
