import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import NewAudience from './new-audience'
import { Col, Container, Row } from 'reactstrap'
import { ReactComponent as TrashIcon } from '../../images/trash.svg'
import { ReactComponent as GroupHandle } from '../../images/group-handle.svg'
import './index.scss'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { accountSubscribe, accountUpdate } from '../../api/accounts'
import ButtonIcon from '../../components/ButtonIcon'
import LanguageSelector from '../../components/language/selector'
import languages from '../../components/language/languages'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'transparent' : 'transparent',
  width: '100%'
})

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#999797' : '#34353B',

  // styles we need to apply on draggables
  ...draggableStyle
})

const AudienceManager = ({ hideSaveButton }) => {
  const [audienceList, setAudienceList] = useState([])
  const [loading, setLoading] = useState(true)
  const [language, setLanguage] = useState({ isEnglish: true, selection: languages[0] })
  const account = useSelector(state => state.accountReducers.account)

  useEffect(() => {
    let unsubscribe = () => {}
    if (account) {
      unsubscribe = accountSubscribe(account.id, subscribedData => {
        setAudienceList(subscribedData.metadata.audience || [])
        setLoading(false)
      })
    } else {
      setLoading(false)
    }

    return unsubscribe
  }, [account])

  const addAudience = (item) => {
    if (item.length > 0) {
      const list = [...audienceList, item]
      setAudienceList(list)
      accountUpdate(account.id, { audience: list })
    }
  }

  const deleteOne = (index) => {
    const newAudience = audienceList.filter((_, i) => i !== index)
    setAudienceList(newAudience)
    accountUpdate(account.id, { audience: newAudience })
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const list = reorder(
      audienceList,
      result.source.index,
      result.destination.index
    )

    accountUpdate(account.id, { audience: _.filter(list, undefined) })
    // accountFormUpdate(account.id, formId, { steps })
  }

  const changeAudienceItem = (index, value) => {
    const newAudience = [...audienceList]
    let element = audienceList[index]
    if (typeof element === 'string') {
      element = {
        en: element
      }
    }
    element[language.selection.code] = value
    newAudience[index] = element
    setAudienceList(newAudience)
    accountUpdate(account.id, { audience: newAudience })
  }

  const onEnterPress = (e, index) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      changeAudienceItem(index, e.target.innerText)
      e.target.blur()
    }
  }

  return <Container fluid className="audience-manager">
    <Row>
      <Col><h1>Audience Manager</h1></Col>
      <Col>
        <LanguageSelector setLanguage={setLanguage}></LanguageSelector>
        <br />
      </Col>
    </Row>
    {loading &&
      <Row><Loader></Loader></Row>
    }

    {audienceList.length > 0 &&
      <Row className="py-4">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
              {[..._.map(audienceList, element => typeof element === 'string' ? element : element[language.selection.code] || element.en), 'Other'].map((item, index) => (
                <Draggable draggableId={`draggable-${index}`} index={index} key={index} className="audience-list">
                  {(provided, snapshot) => (

                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className="audience-list-item"
                      key={index}
                    >
                      <span
                        className="audience-item-editable"
                        contentEditable={item !== 'Other'}
                        onBlur={(e) => { changeAudienceItem(index, e.target.innerText) }}
                        onKeyDown={e => onEnterPress(e, index)}
                        >{item}</span>
                      {item !== 'Other' &&
                        <>
                          <div className="group-handle" {...provided.dragHandleProps}>
                            <GroupHandle />
                          </div>
                          <ButtonIcon onClick={() => deleteOne(index)} className="delete-button">
                            <TrashIcon />
                          </ButtonIcon>
                        </>
                      }
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Row>
    }

    {language.isEnglish &&
      <Row>
        <Col>
          <NewAudience
            hideSaveButton={hideSaveButton}
            onSave={addAudience}
            key={audienceList.length}
          ></NewAudience>
        </Col>
      </Row>
    }
  </Container>
}

export default AudienceManager
