import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Col, Container, Row } from 'reactstrap'
import { queryRun } from '../../api/query'
import ResultsTable from '../query/results-table'
import { useSelector } from 'react-redux'
import Chart from './chart'
import './index.scss'

const periodOptions = [
  'monthly', 'weekly'
]

const ActiveUsers = () => {
  const account = useSelector(state => state.accountReducers.account)
  const [loading, setLoading] = useState(false)
  const [period, setPeriod] = useState('monthly')
  const [results, setResults] = useState([])
  const [error, setError] = useState(null)

  const buildQuery = () => {
    if (period === 'monthly') {
      return 'SELECT year, month, count(*) AS active_users FROM ( SELECT user_id, COUNT(*) AS count, EXTRACT(MONTH FROM date) AS month, EXTRACT(YEAR FROM date) AS year FROM `EVENTS_TABLE` WHERE name = \'journal\' AND EXTRACT(YEAR FROM date) = 2024 GROUP BY user_id, month, year ) AS user_journal_count GROUP BY year, month ORDER BY month ASC'
    } else {
      return 'SELECT year, week, count(*) AS active_users FROM ( SELECT user_id, COUNT(*) AS count, EXTRACT(WEEK FROM date) AS week, EXTRACT(YEAR FROM date) AS year FROM `EVENTS_TABLE` WHERE name = \'journal\' AND EXTRACT(YEAR FROM date) = 2024 GROUP BY user_id, week, year ) AS user_journal_count GROUP BY year, week ORDER BY week ASC'
    }
  }

  const runQuery = async () => {
    const query = buildQuery()

    setLoading(true)
    setError(null)

    const response = await queryRun(query, account.id)

    if (response.error) {
      setError(response.error)
    } else {
      setResults(response.results)
    }

    setLoading(false)
  }

  useEffect(() => {
    runQuery()
  }, [period])

  const chartableData = (data) => {
    const labels = []
    const values = []

    _.forEach(data, row => {
      let date = new Date()
      console.log(row)
      if (row.month) {
        date = new Date(`${row.year}-${row.month}-01`)
      }

      if (row.week) {
        const d = (1 + (row.week - 1) * 7)
        date = new Date(row.year, 0, d)
      }

      labels.push((new Date(date)).toISOString().slice(0, 7))
      values.push(row.active_users)
    })

    return { labels, values }
  }

  return (
    <Container fluid className="active-users">
      <Row>
        <Col>
          <h1>Active Users</h1>
          <p>This section is a &quot;Work in progress&quot;</p>
        </Col>
      </Row>
      <Row className="py-4">
        <Col>
          <h4>Period</h4>
          <select
            className='input-container__input'
            onChange={e => setPeriod(e.target.value)}
          >
            {periodOptions.map((option) => (
              <option
                value={option}
                key={option}
              >{option}</option>
            ))}
          </select>
        </Col>
        <Col>

        </Col>
      </Row>

      {error &&
        <Row className="py-4 error">
          {error}
        </Row>
      }

      {loading &&
        <Row className="py-4">
          Loading...
        </Row>
      }

      {results.length > 0 &&
        <Row className="py-4" key={`chart-${JSON.stringify(results)}`}>
          <Chart data={chartableData(results)}></Chart>
        </Row>
      }

      {results.length > 0 &&
        <Row className="py-4">
          <ResultsTable results={results} />
        </Row>
      }

    </Container>
  )
}

export default ActiveUsers
