import { v4 as uuid } from 'uuid'
import analyze from 'rgbaster'
import rgbHex from 'rgb-hex'
import React, { useCallback, useState } from 'react'
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { useDropzone } from 'react-dropzone'
import Button from '../Button'
import InputContainer from '../InputContainer'
import './index.scss'
import { cssColor } from '../../utils/css-helpers'

const DropZone = (props) => {
  const {
    label,
    name,
    required,
    style = {},
    path,
    innerClassName = '',
    setDominantColor = () => {},
    uploadStart = () => {},
    uploadSuccess,
    uploadEnd = () => {}
  } = props

  const [uploadProgress, setUploadProgress] = useState()
  const [buttonText, setButtonText] = useState('Upload')

  const processUpload = (file) => {
    uploadStart()
    const regex = /[^a-z0-9/.]/gi
    const filename = file.name.replace(regex, '')
    const storage = getStorage()
    const storageRef = ref(storage, `${path}/${uuid()}-${filename}`)
    console.log(storage)
    const uploadTask = uploadBytesResumable(storageRef, file)
    uploadTask.on(
      'state_changed',
      async (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setUploadProgress(progress)
        setButtonText(`${Math.floor(progress)}%`)
        if (progress === 100) {
          console.log(`${storageRef.fullPath}`)
          uploadSuccess(`${storageRef.fullPath}`)
          const resource = URL.createObjectURL(file)

          if (file.type.indexOf('image') === 0) {
            try {
              const result = await analyze(resource, {
                scale: 0.6,
                ignore: [
                  'rgb(255,255,255)',
                  'rgb(0,0,0)'
                ]
              }
              )
              if (result[0]?.color) {
                setDominantColor(cssColor(rgbHex(result[0].color)))
              }
            } catch (e) {
            }
          }

          uploadEnd()
        }
      },
      (e) => {
        console.log('Upload error', e)
        uploadEnd()
      }
    )
  }

  const onDrop = useCallback((acceptedFiles) => {
    processUpload(acceptedFiles[0])
  }, [])
  const {
    getRootProps, getInputProps
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false
  })

  return (
    <InputContainer label={label} name={name} required={required} style={style}>
      {
        uploadProgress !== 100
          ? (
            <div {...getRootProps()} className={innerClassName}>

              <input {...getInputProps()} />
              <Button btnStyle='outline' text={buttonText} />
            </div>
            )
          : <p className='input-container__text'>File uploaded</p>
      }
    </InputContainer>
  )
}

export default DropZone
