import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  account: false
}

export const account = createSlice({
  name: 'Account',
  initialState,
  reducers: {
    setAccount: (state, action) => ({
      ...state,
      account: {
        ...action.payload,
        loaded: true
      }
    }),
    unsetAccount: (state) => ({
      ...state,
      account: false
    })
  }
})

// for redux (sync)
export const { actions } = account
export const setAccount = account.actions.setAccount

export default account.reducer
