import React from 'react'
import _ from 'lodash'
import { Col, Container, Row } from 'reactstrap'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import dateFormat from 'dateformat'
import deleteIcon from '../../images/delete.svg'
import rotate from '../../images/rotate.svg'
import './summary-item.scss'

const C = ({ summary, showAdvanced, setDeleteModal, opened, setOpened, changeSettings, settings }) => {
  const toggle = () => {
    if (opened) {
      setOpened(false)
    } else {
      setOpened(summary.id)
    }
  }

  return (
    <Container fluid className="what-to-build-summary" key={summary.id} data-id={summary.id}>
      <Row onClick={() => {
        console.log(summary.id)
        toggle()
      }} className="wtb-header">
        <Col>
          {dateFormat(summary.metadata.createdAt.toDate(), 'mmmm d yyyy')}

          {summary.metadata.inputs.settingId && _.find(settings, { id: summary.metadata.inputs.settingId }) &&
            <span> - {_.find(settings, { id: summary.metadata.inputs.settingId })?.metadata?.name}</span>
          }
          {summary.metadata.inputs.feel &&
            <span> - {summary.metadata.inputs.feel}</span>
          }
          {summary.metadata.inputs.action &&
            <span> - {summary.metadata.inputs.action}</span>
          }
          {summary.metadata.inputs.duration &&
            <span> - {summary.metadata.inputs.duration}</span>
          }
          {summary.metadata.inputs.audience &&
            <span> - {summary.metadata.inputs.audience}</span>
          }

          {summary.metadata.inputs?.settingId &&
            <img
              className="rotate-icon"
              alt="Use settings"
              src={rotate}
              onClick={(e) => {
                e.stopPropagation()
                changeSettings(summary.metadata.inputs)
              }}
            />
          }
          <img
            onClick={(e) => {
              e.stopPropagation()

              setDeleteModal(summary)
            }}
            src={deleteIcon}
            alt="Delete"
            className="delete-icon"
          />
        </Col>
      </Row>
      {opened && (
        <>
          {showAdvanced &&
            <Row className="wtb-system-message">
              <Col>
                {summary.metadata.inputs.systemMessage}
              </Col>
            </Row>
          }
          <Row className="wtb-content">
            <Col>
              <ReactMarkdown remarkPlugins={[gfm]}>{summary.metadata.summary}</ReactMarkdown>
            </Col>
          </Row>
        </>
      )}

    </Container>
  )
}

export default C
