import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'

import AdminLayout from '../layouts/AdminLayout'
import Home from '../modules/home'
import Query from '../modules/query'
import RawData from '../modules/raw-data'
import FixOccupation from '../modules/fix-audience'
import DeepDive from '../modules/deep-dive'
import DeepDiveSettingsEdit from '../modules/deep-dive/edit-settings'
import FormManager from './form-manager'
import AudienceManager from '../modules/audience-manager'
// import AccountUsers from '../modules/account/users'
import ActiveUsers from '../modules/active-users'

import { AccountGuard, UnregisteredUsersGuard } from './guards'

const AccountRoutes = memo(() => (
  <Routes>
    <Route element={<AdminLayout />}>

      <Route
        path="/"
        element={
          <UnregisteredUsersGuard>
            <AccountGuard>
              <Home />
            </AccountGuard>
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="/query"
        element={
          <UnregisteredUsersGuard>
            <Query />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="/raw-data"
        element={
          <UnregisteredUsersGuard>
            <RawData />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="/deep-dive"
        element={
          <UnregisteredUsersGuard>
            <DeepDive />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="/deep-dive/settings/:id"
        element={
          <UnregisteredUsersGuard>
            <DeepDiveSettingsEdit />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="/fix-audience/:id"
        element={
          <UnregisteredUsersGuard>
            <FixOccupation />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="/form-manager/*"
        element={
          <UnregisteredUsersGuard>
            <FormManager />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="/audience-manager"
        element={
          <UnregisteredUsersGuard>
            <AudienceManager />
          </UnregisteredUsersGuard>
        }
      />
      <Route
        path="/active-users"
        element={
          <UnregisteredUsersGuard>
            <ActiveUsers />
          </UnregisteredUsersGuard>
        }
      />

      <Route
        path="*"
        element={
          <center>
            <p>404</p>
          </center>
        }
      />
    </Route>
  </Routes>
))

AccountRoutes.displayName = 'AccountRoutes'

export default AccountRoutes
