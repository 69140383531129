import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'

import Home from '../modules/form-manager/list'
import SelectTemplate from '../modules/form-manager/select-template'
import Edit from '../modules/form-manager/edit'
import View from '../modules/form-manager/view'

const AccountRoutes = memo(() => (
  <Routes>
    <Route
      path='/'
      element={(
          <Home />
      )}
    />

    <Route
      path='/select-template'
      element={(
          <SelectTemplate />
      )}
    />

  <Route
      path='/edit/:id'
      element={(
          <Edit />
      )}
    />

  <Route
      path='/view/:id'
      element={(
          <View />
      )}
    />

  </Routes>

))

AccountRoutes.displayName = 'AccountRoutes'

export default AccountRoutes
