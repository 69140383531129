import React, { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet } from 'react-router'
import { checkUser } from '../../core/reducers/user'
import Loader from '../../components/Loader'
import './index.scss'
import logo from '../../images/logo.svg'

const OnboardingLayout = memo(() => {
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.rootReducers.isLoading)
  useEffect(() => {
    dispatch(checkUser())
  }, [dispatch])

  return (
    <div className="onboarding-container">
      <div className="onboarding-header">
        <img src={logo} alt='logo' className='logo' />
      </div>
      <div className='onboarding-layout'>

        {
          isLoading ? (<Loader />) : (<Outlet />)
        }
      </div>
    </div>
  )
})

OnboardingLayout.displayName = 'OnboardingLayout'

export default OnboardingLayout
