import { functions, httpsCallable } from '../firebase'
import { getOne, remove, subscribeAll, subscribeOne, update, subscribe, getFiltered } from '../db'

const collectionName = 'accounts'

const accountCreateByUser = async (projectName) => {
  const call = httpsCallable(functions, 'accountCreateByUser')
  const { data } = await call({ projectName })
  return data
}

const accountDelete = async (id) => {
  await remove(collectionName, id)
}

const accountGetById = async (id) => {
  return getOne(collectionName, id)
}

const accountUpdate = async (id, data) => {
  const updateData = {
    ...data,
    updatedAt: new Date()
  }
  return update(collectionName, id, updateData)
}

const accountGetAudiences = async (accountId) => {
  const call = httpsCallable(functions, 'accountGetAudiences')
  const { data } = await call({ accountId })
  return data
}

const accountManageJoinLink = async (data) => {
  const call = httpsCallable(functions, 'accountManageJoinLink')
  return await call(data)
}

const accountMasterAccountsSubscribe = (mutationCallback) => subscribe(collectionName, [['masterAccount', '==', true]], mutationCallback)

const accountSoftDelete = async ({ accountId, deleted }) => {
  const call = httpsCallable(functions, 'accountSoftDelete')
  return await call({ accountId, deleted })
}

const accountSubscribe = (id, mutationCallback) => subscribeOne(collectionName, id, mutationCallback)

const accountsSubscribeAll = (mutationCallback) => subscribeAll(collectionName, mutationCallback)

const accountsGetByUser = async (accountIds) => {
  const accounts = []
  for (let a = 0; a < accountIds.length; a++) {
    const account = await accountGetById(accountIds[a])
    accounts.push(account)
  }
  return accounts
}

const accountsGetByMasterAccount = async (masterAccount) => {
  try {
    const subaccounts = masterAccount.metadata.subaccounts || []
    const accounts = []
    for (let a = 0; a < subaccounts.length; a++) {
      const account = await accountGetById(masterAccount.metadata.subaccounts[a])
      accounts.push(account)
    }

    accounts.push(masterAccount)
    return accounts
  } catch (error) {
    console.log('Error getting accountsGetByMasterAccount', error, masterAccount)
    return []
  }
}

const accountsGetByParentAccount = async (accountId) => {
  const accounts = await getFiltered(collectionName, [['parentAccount', '==', accountId]])

  return accounts
}

const accountSwitch = (accountId) => {
  const call = httpsCallable(functions, 'accountSwitch')
  return call({ accountId })
}

const accountGetTeamStats = (accounts) => {
  const call = httpsCallable(functions, 'accountGetTeamStats')
  return call({ accounts })
}

export {
  accountCreateByUser,
  accountDelete,
  accountGetAudiences,
  accountGetById,
  accountsGetByMasterAccount,
  accountsGetByParentAccount,
  accountsGetByUser,
  accountGetTeamStats,
  accountManageJoinLink,
  accountMasterAccountsSubscribe,
  accountUpdate,
  accountSoftDelete,
  accountSwitch,
  accountsSubscribeAll,
  accountSubscribe
}
