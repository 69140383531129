import React, { useState } from 'react'
import _ from 'lodash'
import { Button, Col, Row } from 'reactstrap'
import TextareaAutosize from 'react-textarea-autosize'
import { clarify } from '../../../api/accounts/forms'
import { useParams } from 'react-router'
import { t } from '../../../utils/translate'

const FormStepTextarea = ({ form, results, step, placeholder, onSelect, onKeyPress }) => {
  const { accountId, formId } = useParams()
  const [value, setValue] = useState(null)
  const [followingValue, setFollowingValue] = useState(null)
  const [askedForClarification, setAskedForClarification] = useState(false)
  const [followingQuestion, setFollowingQuestion] = useState(false)
  const [okDisabled, setOkDisabled] = useState(false)

  const validate = async (text) => {
    if (!step.aiClarification) {
      return onSelect(text)
    }

    const messages = [{
      role: 'assistant',
      content: form.metadata.steps[0].question
    },
    {
      role: 'user',
      content: results[0].value
    },
    {
      role: 'assistant',
      content: step.question
    },
    {
      role: 'user',
      content: text
    },
    {
      role: 'system',
      content: `You are about to ask to clarify or get more information about question ${step.question} Ask as short question as is possible to help us understand user answer.`
    }]

    const response = await clarify(accountId, formId, messages)

    if (_.has(response, 'data.choices[0].message.content')) {
      setFollowingQuestion(response.data.choices[0].message.content)
    }

    setOkDisabled(false)
  }

  const okClicked = () => {
    if (!form.metadata.askedForClarificationEnabled) {
      return onSelect(value)
    }

    setOkDisabled(true)

    if (followingQuestion) {
      return onSelect(value + ' ' + followingValue)
    }

    if (!value) {
      return setAskedForClarification(true)
    }

    if (value.length === 0) {
      return onSelect(value)
    }

    if (askedForClarification) {
      return validate(value)
    }

    if (value.length < 20) {
      return setAskedForClarification(true)
    }

    return validate(value)
  }

  return (
    <div className="step-inputs">
      <style type="text/css">
      textarea {'{'}
        &::placeholder {'{'}
          {`color: ${form.metadata.colors.brandLight} !important;`}
          {'}'}
        {'}'}
      </style>
      {askedForClarification && <p>{t('Could you be more specific?')}</p>}
      <Row>
        <Col>
          {followingQuestion &&
            <p className="user-input">{value}</p>
          }
          {!followingQuestion &&
            <TextareaAutosize
              style={{
                backgroundColor: form.metadata.colors.background,
                color: form.metadata.colors.brand,
                borderColor: form.metadata.colors.brandLight
              }}
              placeholder={t(placeholder)}
              onKeyUp={(e) => {
                setOkDisabled(false)
                setValue(e.target.value)
                onKeyPress(e.target.value)
              }}
              onChange={(e) => {
                setOkDisabled(false)
                setValue(e.target.value)
                onKeyPress(e.target.value)
              }}
              autoFocus
            />
          }

        </Col>
      </Row>
      {followingQuestion &&
        <Row className="following-question">
          <Col>
            <p>{followingQuestion}</p>
              <TextareaAutosize
                placeholder={t(placeholder)}
                onKeyUp={(e) => {
                  setOkDisabled(false)
                  setFollowingValue(e.target.value)
                }}
                onChange={(e) => {
                  setOkDisabled(false)
                  setFollowingValue(e.target.value)
                }}
                autoFocus
              />
          </Col>
        </Row>
      }
      <Row>
        <Col>
          <Button
            style={{
              borderColor: form.metadata.colors.brand,
              backgroundColor: form.metadata.colors.brandLight,
              color: form.metadata.colors.brand
            }}
            onClick={() => okClicked()}
            disabled={okDisabled}>{t('ok')}</Button>
        </Col>
      </Row>
    </div>
  )
}

export default FormStepTextarea
