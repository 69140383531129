import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { backgroundColorPlugin } from '../../modules/home/chart-utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  tension: 0,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    backgroundColorPlugin: {
      color: 'transparent'
    }
  },
  elements: {
    point: {
      radius: 2,
      borderWidth: 0
    }
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        callback: function (val, index) {
          // if (index % 2 === 0) return ''

          const date = this.getLabelForValue(val)
          return date
        }
      }
    },
    y: {
      grid: {
        color: '#313537'
      },
      ticks: {
        callback: function (val, index) {
          const data = this.getLabelForValue(val)
          return data
        },
        font: {
          size: 14
        }
      }
    }
  }
}

const C = ({ data, title }) => {
  const { labels } = data
  const datasets = [{
    data: data.values,
    backgroundColor: '#FDF38B',
    borderColor: '#FDF38B',
    cubicInterpolationMode: 'monotone',
    lineTension: 0
  }]

  return (
    <div className="chart-area">
      <Line plugins={[backgroundColorPlugin]} data={{ labels, datasets }} options={options} />
    </div>
  )
}

export default C
