import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { whatToBuildSummariesSubscribeAll, whatToBuildSummariesDelete } from '../../api/accounts/what-to-build-summaries'
import SummaryItem from './summary-item'
import DeleteModal from './delete-modal'
import './summary-item.scss'

const C = ({ changeSettings, showAdvanced, opened, setOpened, settings }) => {
  const account = useSelector(state => state.accountReducers.account)
  const [summaries, setSummaries] = useState(false)
  const [summary, setSummary] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    return whatToBuildSummariesSubscribeAll(account.id, (summaries) => {
      setSummaries(summaries)
    })
  }, [])

  const deleteConfirmed = async () => {
    await whatToBuildSummariesDelete(account.id, summary.id)
    setDeleteModal(false)
    setSummary(false)
  }

  return (<div className="summaries">
    {summaries && _.orderBy(summaries, 'metadata.createdAt')
      .reverse()
      .map((summary, i) => (
        <SummaryItem
          key={i}
          summary={summary}
          showAdvanced={showAdvanced}
          setDeleteModal={(summary) => {
            setSummary(summary)
            setDeleteModal(true)
          }}
          opened={opened === summary.id}
          setOpened={setOpened}
          changeSettings={changeSettings}
          settings={settings}
        />
      ))}

    <DeleteModal
      modal={deleteModal}
      setModal={setDeleteModal}
      deleteConfirmed={() => deleteConfirmed()}
      summary={summary}
    ></DeleteModal>
  </div>)
}

export default C
