import React, { useState } from 'react'
import cssColorFormat from 'css-color-checker'
import { Container, Input, Row } from 'reactstrap'

const ColorRow = ({ onChange, label, defaultValue }) => {
  const [value, setValue] = useState(defaultValue)

  const changeValue = (value) => {
    setValue(value)
    onChange(value)
  }

  const fixColorIssues = (value) => {
    console.log('fixColorIssues', value)
    console.log(!cssColorFormat(value), cssColorFormat(`#${value}`) === 'hex')
    if (!cssColorFormat(value) && cssColorFormat(`#${value}`) === 'hex') {
      setValue(`#${value}`)
      return onChange(`#${value}`)
    }

    setValue(value)
    onChange(value)
  }

  return (
    <Container className="color-box">
      <Row className="label">
          <label>{label}:</label>
      </Row>
      <Row className="input-preview">
        <div className='colorBox' style={{ backgroundColor: value }}></div>
        <Input type="text" value={value} onChange={(e) => changeValue(e.target.value)} onBlur={(e) => fixColorIssues(e.target.value)} />
      </Row>
    </Container>
  )
}

export default ColorRow
