import homeControl from './home-control'
import rootReducers from './root'
import userReducers from './user'
import accountReducers from './account'
import mentalReadiness from './mental-readiness'

const reducers = {
  homeControl,
  rootReducers,
  userReducers,
  accountReducers,
  mentalReadiness
}

export default reducers
