import React from 'react'
import { Col } from 'reactstrap'
import './home-section.scss'

const C = ({ children, name }) => {
  return (
    <Col className={`home-section ${name}`}>
      {children}
    </Col>
  )
}

export default C
