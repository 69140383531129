import React from 'react'
import cssColorFormat from 'css-color-checker'
import { Col, Row } from 'reactstrap'

const ColorRow = ({ onChange, label, value, extraColor }) => {
  const fixColorIssues = (value) => {
    if (!cssColorFormat(value) && cssColorFormat(`#${value}`) === 'hex') {
      return onChange(`#${value}`)
    }

    onChange(value)
  }

  return (
    <Row>
      <Col>
        <label>{label}:</label>
      </Col>
      <Col>
        <input type="text" value={value} onChange={(e) => onChange(e.target.value)} onBlur={(e) => fixColorIssues(e.target.value)} />
      </Col>
      <Col>
        <div className='colorBox' style={{ backgroundColor: value }}></div>
        {extraColor && <div className='colorBox' style={{ backgroundColor: extraColor }}></div>}
      </Col>
    </Row>
  )
}

export default ColorRow
