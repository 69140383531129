import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Loader from '../../components/Loader'
import { Button, Col, Container, Progress, Row } from 'reactstrap'
import { getForm, partialSubmit } from '../../api/accounts/forms'
import Step from './step'
import './index.scss'
import { accountGetAudiences } from '../../api/accounts'
import { t } from '../../utils/translate'
// import PMF from 'pmf-engine-sdk'

// let pmf

const PmfForm = () => {
  const [processId] = useState(uuid())
  const { accountId, formId, userId, userAgent } = useParams()
  const [loading, setLoading] = useState(true)
  const [form, setForm] = useState(null)
  const [formKey, setFormKey] = useState(uuid())
  const [step, setStep] = useState(false)
  const [steps, setSteps] = useState([])
  const [stepIndex, setStepIndex] = useState(0)
  const [canForward, setCanForward] = useState(false)
  const [accountName, setAccountName] = useState(false)
  const [languages, setLanguages] = useState()
  const [audiences, setAudiences] = useState([])
  const [results, setResults] = useState([])
  const [is404, set404] = useState(false)

  /*
  useEffect(() => {
    pmf = new PMF(accountId, {
      form: {
        id: formId
      }
    })
    pmf.identify(userId)
  }, [accountId, userId])
  */

  const setResult = (step, value) => {
    const newResults = _.reject(results, { name: step.name })
    const data = [...newResults, { name: step.name, value }]
    setResults(data)

    const dataToSave = {
    }

    data.forEach(row => {
      dataToSave[row.name] = row.value
    })

    if (userId) {
      dataToSave.user_id = userId
    }

    if (userAgent) {
      dataToSave.user_agent = userAgent
    }

    if (data.length > 1) {
      partialSubmit(accountId, formId, processId, dataToSave)
    }
  }

  useEffect(() => {
    getForm(accountId, formId)
      .then(formData => {
        if (!formData.metadata.colors) {
          setForm({
            ...formData,
            metadata: {
              ...formData.metadata,
              colors: {
                background: '#fff',
                text: '#000',
                brand: '#000',
                brandLight: '#aaa'
              }
            }
          })
        } else {
          setForm(formData)
        }
        setLoading(false)
        const formSteps = _.filter(formData.metadata.steps, step => !(step.name === 'email' && formData.metadata.emailQuestionSkipped))
        setStep(formSteps[0])
        setSteps(formSteps)
        setFormKey(uuid())
      }).catch(err => {
        console.error(err)
        console.log('setting 404')
        set404(true)
        setLoading(false)
      })

    accountGetAudiences(accountId)
      .then(({ audiences, accountName, languages: accountLanguages }) => {
        setFormKey(uuid())
        setAccountName(accountName)
        setLanguages(accountLanguages || [{ code: 'en', name: 'English' }])
        if (audiences && audiences.length > 0) {
          setAudiences([...audiences, 'Other'])
        }
      })
  }, [accountId, formId])

  useEffect(() => {
    if (!form) {
      return () => {}
    }

    if (!steps[stepIndex + 1]) {
      setCanForward(false)
      return () => {}
    }

    if (steps[stepIndex]) {
      const name = steps[stepIndex].name
      if (_.find(results, { name })) {
        setCanForward(true)
        return () => {}
      }
    }

    setCanForward(false)
  }, [form, step, steps, results])

  const nextStep = (direction = 1) => {
    const currentIndex = _.findIndex(steps, { name: step.name })
    setStepIndex(currentIndex + direction)
    setStep(steps[currentIndex + direction])
    // pmf.event(`step ${currentIndex + direction}`)
  }

  return (
    <div
      className="form-wrapper"
      style={{
        backgroundColor: form?.metadata?.colors?.background || '#fff',
        color: form?.metadata?.colors?.text || '#000',
        border: `1px solid ${form?.metadata?.colors?.background || '#fff'}`
      }}>
      {step && step.question &&
        <Container className="step-progress">
           <style type="text/css">
           .step-progress .progress {'{'}
              {`border-color: ${form.metadata.colors.brand} !important;`}
              {'}'}
              .step-progress .progress .progress-bar {'{'}
              {`background-color: ${form.metadata.colors.brand} !important;`}
            {'}'}
          </style>
          <Row>
            <Col xs="2" sm="1">{1 + stepIndex}/{steps.length}</Col>
            <Col xs="10" sm="11">
              <Progress value={ 100 * (1 + stepIndex) / steps.length} />
            </Col>
          </Row>
        </Container>
      }
      <Container className="pmf-form" key={formKey}>
        {loading && <Loader />}
        {!loading && !is404 && (
          <Step
          accountName={accountName}
          audiences={audiences}
          step={step}
            stepIndex={stepIndex}
            form={form}
            processId={processId}
            nextStep={() => nextStep()}
            setResult={setResult}
            submitResults={() => {}}
            results={results}
            languages={languages}
          ></Step>
        )}
        {!loading && is404 && (
          <Row>
            <center>
              <h1>{t('Form not found')}</h1>
            </center>
          </Row>
        )}
      </Container>

      {step &&
        <div className="steps-navigation">
          <Container>
            <Row>
              <Col>
                <Button
                  style={{
                    borderColor: form.metadata.colors.brand,
                    backgroundColor: form.metadata.colors.brandLight,
                    color: form.metadata.colors.brand
                  }}
                  onClick={() => nextStep(-1)}
                  disabled={!steps[stepIndex - 1]}
                >&lt;</Button>
                <Button
                  style={{
                    borderColor: form.metadata.colors.brand,
                    backgroundColor: form.metadata.colors.brandLight,
                    color: form.metadata.colors.brand
                  }}
                  onClick={() => nextStep()}
                  disabled={!canForward}
                  >&gt;</Button>
              </Col>
            </Row>
          </Container>
        </div>
      }

    </div>
  )
}

export default PmfForm
