import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'

import OnboardingLayout from '../layouts/OnboardingLayout'
import Home from '../modules/onboarding'
import Audience from '../modules/onboarding/audience'
import Project from '../modules/onboarding/project'
import Form from '../modules/onboarding/form'
import Sdk from '../modules/onboarding/sdk'

import { UnregisteredUsersGuard } from './guards'
import GuestLayout from '../layouts/GuestLayout'

const OnboardingRoutes = memo(() => (
  <Routes>
    <Route element={<OnboardingLayout />}>
      <Route
        path='/'
        element={(
          <Home />
        )}
      />
      <Route
        path='/project'
        element={(
          <Project />
        )}
      />
      <Route
        path='/audience'
        element={(
          <UnregisteredUsersGuard>
            <Audience />
          </UnregisteredUsersGuard>
        )}
      />
      <Route
        path='/form'
        element={(
          <UnregisteredUsersGuard>
            <Form />
          </UnregisteredUsersGuard>
        )}
      />
      <Route
        path='/sdk'
        element={(
          <UnregisteredUsersGuard>
            <Sdk />
          </UnregisteredUsersGuard>
        )}
      />
    </Route>
    <Route path='/*' element={<GuestLayout />}>
      <Route path="*" element={<p>404</p>} />
    </Route>
  </Routes>
))

OnboardingRoutes.displayName = 'OnboardingRoutes'

export default OnboardingRoutes
