import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Input, Label, Row } from 'reactstrap'
import './index.scss'

const AudienceManagerNewAudience = ({ onSave, disabled }) => {
  const [audienceName, setAudienceName] = useState('')
  const textInput = useRef(null)

  useEffect(() => {
    textInput.current.focus()
  }, [])

  const onEnterPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      onSave(e.target.value)
    }
  }

  return (<Row className="new-audience-form">
      <Label>Enter audience segment</Label>
      <Row>
        <Col className="col-10">
          <Input
            disabled={disabled}
            innerRef={textInput}
            placeholder="e.g. Student, or Manager, or Wine lover"
            value={audienceName}
            onChange={(e) => setAudienceName(e.target.value)}
            onKeyDown={onEnterPress}
            ></Input>
        </Col>
        <Col className="col-2">
          <Button
            disabled={disabled}
            color="primary"
            className="add-audience-button"
            onClick={() => onSave(audienceName)}><span>+</span>
          </Button>
        </Col>
      </Row>
    </Row>)
}

export default AudienceManagerNewAudience
