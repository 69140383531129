import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Input, Label, Row } from 'reactstrap'
import hexToRgba from 'hex-to-rgba'
import { accountFormSubscribe, accountFormUpdate } from '../../../api/accounts/forms'
import Loader from '../../../components/Loader'
import { useParams } from 'react-router'
import FormStepTile from './step-tile'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ColorRow from './color-row'
import './index.scss'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'transparent' : 'transparent',
  padding: grid,
  width: 250
})

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#999797' : '#606872',

  // styles we need to apply on draggables
  ...draggableStyle
})

const lightenBrandColor = 0.5

function shadeColor (color, percent) {
  return hexToRgba(color, percent)
}

const FormManagerEdit = () => {
  const account = useSelector(state => state.accountReducers.account)
  const [form, setForm] = useState(null)
  const [loading, setLoading] = useState(true)
  const [thankyouTitle, setThankyouTitle] = useState('')
  const [thankyouSubtitle, setThankyouSubtitle] = useState('')
  const [slackWebhook, setSlackWebhook] = useState('')
  const [backgroundColor, setBackgroundColor] = useState('')
  const [textColor, setTextColor] = useState('')
  const [brandColor, setBrandColor] = useState('')

  const formId = useParams().id

  useEffect(() => {
    const unsubscribe = accountFormSubscribe(account.id, formId, (formData) => {
      setForm(formData)

      setThankyouTitle(formData.metadata.thankyou?.title || '')
      setThankyouSubtitle(formData.metadata.thankyou?.subtitle || '')
      setSlackWebhook(formData.metadata.slackWebhook)

      setBackgroundColor(formData.metadata.colors?.background || '')
      setTextColor(formData.metadata.colors?.text || '')
      setBrandColor(formData.metadata.colors?.brand || '')

      setLoading(false)
    })

    return unsubscribe
  }, [account, formId])

  const onDragEnd = (result) => {
    console.log(result)

    // dropped outside the list
    if (!result.destination) {
      return
    }

    const steps = reorder(
      form.metadata.steps,
      result.source.index,
      result.destination.index
    )

    accountFormUpdate(account.id, formId, { steps })
  }

  const updateThankyouTitle = (title) => {
    setThankyouTitle(title)
    accountFormUpdate(account.id, formId, { thankyou: { title, subtitle: thankyouSubtitle } })
  }

  const updateThankyouSubtitle = (subtitle) => {
    setThankyouSubtitle(subtitle)
    accountFormUpdate(account.id, formId, { thankyou: { title: thankyouTitle, subtitle } })
  }

  const updateSlackWebhook = (slackWebhook) => {
    setSlackWebhook(slackWebhook)
    accountFormUpdate(account.id, formId, { slackWebhook })
  }

  const updateBackgroundColor = (value) => {
    setBackgroundColor(value)
    accountFormUpdate(account.id, formId, {
      colors:
      {
        background: value,
        text: textColor,
        brand: brandColor,
        brandLight: shadeColor(brandColor, lightenBrandColor)
      }
    })
  }

  const updateTextColor = (value) => {
    setTextColor(value)
    accountFormUpdate(account.id, formId, {
      colors:
      {
        background: backgroundColor,
        text: value,
        brand: brandColor,
        brandLight: shadeColor(brandColor, lightenBrandColor)

      }
    })
  }

  const updateBrandColor = (value) => {
    setBrandColor(value)
    accountFormUpdate(account.id, formId, {
      colors: {
        background: backgroundColor,
        text: textColor,
        brand: value,
        brandLight: shadeColor(value, lightenBrandColor)
      }
    })
  }

  const setEmailSkipped = (value) => {
    accountFormUpdate(account.id, form.id, {
      emailQuestionSkipped: !value
    })
  }

  return (
    <Container fluid>
      <Row>
        <Col>
        </Col>
        <Col></Col>
        <Col>
        </Col>
      </Row>
        {!loading && form &&
          <Row>
            <h1>Form {form.metadata.name || 'Unnamed'}</h1>
          </Row>
        }
        {!loading && form &&
          <>
            <Row>
              <div className="mt-3">Test URL: <a href={`https://pmf-engine.com/form/${account.id}/${formId}/testUser/testUseragent`} target="_blank" rel="noreferrer">{`https://pmf-engine.com/form/${account.id}/${formId}/testUser/testUseragent`}</a></div>
            </Row>
            <Row>

              <Col className="col-9">
                <h3 className="my-3">Colors</h3>
                <Container>
                  <ColorRow
                    label="Background color"
                    value={backgroundColor}
                    onChange={updateBackgroundColor}
                  />

                  <ColorRow
                    label="Text color"
                    value={textColor}
                    onChange={updateTextColor}
                  />

                  <ColorRow
                    label="Brand color"
                    value={brandColor}
                    extraColor={shadeColor(brandColor, lightenBrandColor)}
                    onChange={updateBrandColor}
                    />
                </Container>
                <div>
                  <h3 className="my-3">Thank you:</h3>
                  <Row>
                    <Col className="col-2">Title</Col>
                    <Col className="col-10">
                      <Input type="text" value={thankyouTitle} onChange={(e) => updateThankyouTitle(e.target.value)} />
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col className="col-2">Subtitle</Col>
                    <Col className="col-10">
                      <Input type="text" value={thankyouSubtitle} onChange={(e) => updateThankyouSubtitle(e.target.value)} />
                    </Col>
                  </Row>
                </div>
                <div>
                  Slack Webhook: <Input type="text" value={slackWebhook} onChange={(e) => updateSlackWebhook(e.target.value)} />
                </div>
              </Col>
              <Col className="col-3">
                <h3 className="my-3">Questions order</h3>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                      className="steps-order"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {form.metadata.steps.map((step, i) => (
                          <Draggable draggableId={`draggable-${i}`} index={i} key={i}>
                            {(provided, snapshot) => (
                              <FormStepTile
                                forwardRef={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                forwardStyle={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                step={step}
                              ></FormStepTile>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <>
                <Label>
                  <Input type="checkbox" onChange={(e) => {
                    setEmailSkipped(e.target.checked)
                  }} checked={!form.metadata.emailQuestionSkipped}></Input>
                &nbsp;Ask users for their email</Label>
                </>
              </Col>
            </Row>
          </>
        }
        {loading && <Row><Loader></Loader></Row>}
    </Container>
  )
}

export default FormManagerEdit
