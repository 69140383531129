import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import { ReactComponent as Plus } from '../../images/plus.svg'
import { ReactComponent as Remove } from '../../images/trash.svg'
import './selector.scss'
import { useSelector } from 'react-redux'
import { accountUpdate } from '../../api/accounts'
import languages from './languages'

const LanguageSelector = ({ setLanguage }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selection, setSelection] = useState(languages[0])
  const [languagesLocalized, setLanguagesLocalized] = useState([languages[0]])
  const [languagesNotLocalized, setLanguagesNotLocalized] = useState(_.filter(languages, { always: false }))
  const account = useSelector((state) => state.accountReducers.account)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const addLanguage = (language) => {
    const newLanguagesLocalized = [...languagesLocalized, language]
    setLanguagesNotLocalized(_.filter(languagesNotLocalized, (l) => l.code !== language.code))
    setLanguagesLocalized(newLanguagesLocalized)
    console.log({ newLanguagesLocalized })
    accountUpdate(account.id, { languages: newLanguagesLocalized })
  }

  const removeLanguage = (language) => {
    const newLanguagesLocalized = _.filter(languagesLocalized, (l) => l.code !== language.code)
    setLanguagesLocalized(newLanguagesLocalized)
    setLanguagesNotLocalized([...languagesNotLocalized, language])
    if (language.code === selection.code) {
      setSelection(languages[0])
    }
    accountUpdate(account.id, { languages: newLanguagesLocalized })
  }

  useEffect(() => {
    if (account) {
      const accountLanguages = account.metadata.languages || [languages[0]]
      const notLocalized = _.filter(languages, l => {
        return _.findIndex(accountLanguages, { code: l.code }) === -1
      })
      setLanguagesLocalized(accountLanguages)
      setLanguagesNotLocalized(notLocalized)
    }
  }, [account])

  useEffect(() => {
    setLanguage({
      isEnglish: selection.code === 'en',
      selection
    })
  }, [selection])

  return (
    <div className="language-selector">
        <Dropdown isOpen={isOpen} toggle={toggle} className='header__dropdown-container'>
          <DropdownToggle tag='span' className='header__dropdown'>
            {selection.name}
          </DropdownToggle>
          <DropdownMenu
            end
            flip={false}
          >
            <p>Localized</p>
            {languagesLocalized.map((language, index) => (
              <Row key={index} className="item-row">
                <DropdownItem onClick={() => { setSelection(language) }}>
                  {language.name}
                </DropdownItem>
                {language.code !== 'en' &&
                  <Remove className="icon" onClick={() => removeLanguage(language)}/>
                }
              </Row>
            ))}

            {languagesNotLocalized.length > 0 &&
              <>
                <p className="non-localized">Non-Localized</p>

                  {languagesNotLocalized.map((language, index) => (
                    <Row key={index} className="item-row">
                      <div className="non-localized-item">
                        {language.name}
                      </div>
                      <Plus className="icon" onClick={() => addLanguage(language)}/>
                    </Row>
                  ))}

              </>
            }

          </DropdownMenu>
        </Dropdown>
    </div>
  )
}

export default LanguageSelector
