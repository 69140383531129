import { create, getOne, remove, subscribe, subscribeOne, update } from '../db'
import { functions, httpsCallable } from '../firebase'

const accountsCollectionName = 'accounts'
const collectionName = 'forms'

const accountFormsSubscribe = (accountId, mutationCallback) => subscribe(`${accountsCollectionName}/${accountId}/${collectionName}`, [['deleted', '==', false]], mutationCallback)
const accountFormSubscribe = (accountId, formId, mutationCallback) => subscribeOne(`${accountsCollectionName}/${accountId}/${collectionName}`, formId, mutationCallback)

const accountFormCreate = async (accountId, formId, data) => {
  const createData = {
    ...data,
    createdAt: new Date(),
    updatedAt: new Date()
  }
  return create(`${accountsCollectionName}/${accountId}/${collectionName}`, formId, createData)
}

const accountFormUpdate = async (accountId, formId, data) => {
  return update(`${accountsCollectionName}/${accountId}/${collectionName}`, formId, data)
}

const accountFormCreated = async (accountId, formId) => {
  const call = httpsCallable(functions, 'formCreated')
  const { data } = await call({ accountId, formId })
  return data
}

const getForm = async (accountId, id) => {
  const form = await getOne(`${accountsCollectionName}/${accountId}/${collectionName}`, id)

  if (!form.exist) throw new Error(`Form ${id} not found`)
  return form
}

const partialSubmit = async (accountId, formId, processId, results) => {
  return update('partial-submission', processId, {
    accountId,
    formId,
    results,
    updatedAt: new Date()
  })
}

const partialCleanup = async (processId) => {
  return remove('partial-submission', processId)
}

const clarify = async (accountId, formId, messages) => {
  const temperature = 0.7
  const maxTokens = 256
  const call = httpsCallable(functions, 'formClarify')
  const { data } = await call({
    accountId,
    formId,
    temperature,
    max_tokens: maxTokens,
    messages
  })
  return data
}

const submit = async (accountId, formId, processId, results) => {
  const call = httpsCallable(functions, 'formSubmit')
  const { data } = await call({ accountId, formId, processId, results })
  return data
}

export {
  accountFormsSubscribe,
  accountFormSubscribe,
  accountFormCreate,
  accountFormCreated,
  accountFormUpdate,
  clarify,
  getForm,
  partialCleanup,
  partialSubmit,
  submit
}
