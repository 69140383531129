import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import './form-preview.scss'

const FormPreview = ({ accountId, form, toggle, formPreview }) => {
  return (<Modal isOpen={formPreview} toggle={toggle} className="form-preview" size="lg">
    <ModalHeader toggle={toggle}>Form preview</ModalHeader>
    <ModalBody>
      <iframe src={`/form/${accountId}/${form.id}`} style={{ width: '100%', height: '100%' }}></iframe>
      <div className="overlay"></div>
    </ModalBody>
  </Modal>)
}

export default FormPreview
