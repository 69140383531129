import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import DropZone from '../../components/DropZone'
import Score from './score'
import Trend from './trend'
import Audience from './audience'
import './index.scss'
import { useSelector } from 'react-redux'
import RevenueByAudience from './revenue-by-audience'

const showRevenue = false

const C = () => {
  const [uploading, setUploading] = useState(false)
  const account = useSelector(state => state.accountReducers.account)
  const uploadPath = 'csvs'

  const setValue = (value) => {
    console.log('setValue', value)
  }

  return (
    <Container fluid>
      <Row>
        <Col>
        </Col>
        <Col></Col>
        <Col>
          <DropZone
            innerClassName="upload-button"
            name='file'
            required={() => true}
            style={{ width: 'auto' }}
            path={uploadPath}
            uploadSuccess={file => setValue(file)}
            uploadStart={() => setUploading(true)}
            uploadEnd={() => setUploading(false)}
          />
          {uploading && <p>Uploading...</p>}
        </Col>
      </Row>
      {account && !account.metadata.submissionsCount && <Row><center>Waiting for the first event.</center></Row>}
      {account && account.metadata.submissionsCount > 0 &&
        <Row>
          <Score />
          <Trend />
          {account.metadata.audience &&
            <Audience />
          }
        </Row>
      }
      {showRevenue && account && account.metadata.revenueTableName &&
        <Row>
          <RevenueByAudience />
        </Row>
      }

    </Container>
  )
}

export default C
