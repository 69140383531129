import _ from 'lodash'
import de from './languages/de'
import { translate } from '../../api/translate'

window.toTranslate = []
const languageFiles = { de }
export const language = `${navigator.language || navigator.userLanguage}`.substring(0, 2)

window.generateTranslationFile = async (language) => {
  console.log('Generating translation file')
  console.log(window.toTranslate[language])
  const { translated } = await translate(window.toTranslate[language], language)

  const toFile = []
  _.each(translated, (translation) => {
    toFile.push(`"${translation.key}": "${translation.translated.text}",`)
  })

  console.log(toFile.join('\n'))
}

const replacePlaceholders = (string, placeholders) => {
  if (placeholders) {
    _.each(placeholders, (value, key) => {
      string = string.replace(value, `{{${key}}}`)
    })
  }

  return string
}

const reversePlaceholders = (string, placeholders) => {
  if (placeholders) {
    _.each(placeholders, (value, key) => {
      string = string.replace(`{{${key}}}`, value)
    })
  }

  return string
}

export const t = (key, placeholders) => {
  if (!key) {
    return key
  }

  const languageFile = languageFiles[language]

  if (!languageFile) {
    return key
  }

  const filteredKey = replacePlaceholders(key, placeholders)

  if (!window.toTranslate[language]) {
    window.toTranslate[language] = []
  }

  if (languageFile[filteredKey]) {
    return reversePlaceholders(languageFile[filteredKey], placeholders)
  }

  if (!languageFile[filteredKey] && window.toTranslate[language].indexOf(filteredKey) === -1) {
    console.log('Adding string to translation')
    console.log(key, filteredKey, placeholders)
    window.toTranslate[language].push(filteredKey)
  }

  return key
}
