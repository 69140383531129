import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { queryRun } from '../../api/query'
import { useSelector, useDispatch } from 'react-redux'
import HomeSection from './home-section'
import { cacheGetItemData, cacheSetItem } from '../../api/cache'
import ScoreChart from './score-chart'
import { setAudienceScore } from '../../core/reducers/home-control'

const query = 'SELECT * FROM ( SELECT feel, COUNT(*) as count FROM `feedback` WHERE GROUP BY feel ) AS test ORDER BY count DESC'

const getAudienceQuery = (audience) => {
  const where = []

  for (let i = 0; i < audience.length; i++) {
    where.push(`audience = '${audience[i]}'`)
  }

  if (audience.length === 0) {
    where.push('false')
  }

  return query.replace('WHERE', `WHERE ${where.join(' OR ')}`)
}

const sortFeel = (data) => {
  const x = _.sortBy(data, row => {
    switch (row.feel) {
      case 'Very disappointed':
        return 1
      case 'Somewhat disappointed':
        return 2
      case 'Neutral':
        return 3
      case 'Somewhat satisfied':
        return 4
      case 'Very satisfied':
        return 5
      default:
        return 6
    }
  })

  return x
}

const chartableData = (data) => {
  const labels = []
  const values = []

  _.forEach(sortFeel(data), ({ feel, count }) => {
    labels.push(feel)
    values.push(100 * count / _.sumBy(data, 'count'))
  })

  return { labels, values }
}

const HomeScore = () => {
  const dispatch = useDispatch()
  const account = useSelector(state => state.accountReducers.account)
  const selectedAudience = useSelector((state) => state.homeControl.audience)
  const score = useSelector((state) => state.homeControl.audienceScore)
  const [scoreResults, setScoreResults] = useState(cacheGetItemData('score-results') || false)
  const [loading, setLoading] = useState(!cacheGetItemData('score'))
  const [dataError, setError] = useState(null)

  useEffect(() => {
    const run = async () => {
      setLoading(true)
      const { results, error } = await queryRun(getAudienceQuery(selectedAudience), account.id)

      if (!error) {
        const all = results.reduce((acc, row) => acc + row.count, 0)
        const scoreValue = _.find(results, { feel: 'Very disappointed' }) ? _.find(results, { feel: 'Very disappointed' }).count / all * 100 : 0
        const scoreResultsWithPercent = _.map(results, row => ({
          ...row,
          percent: row.count / all * 100
        }))

        dispatch(setAudienceScore(scoreValue))
        setScoreResults(scoreResultsWithPercent)
        setLoading(false)
        cacheSetItem('score', scoreValue)
        cacheSetItem('score-results', scoreResultsWithPercent)
      } else {
        console.log(error)
        setError(error)
      }
    }

    if (selectedAudience.length > 0) {
      run()
    } else {
      dispatch(setAudienceScore(false))
      setScoreResults(false)
      setLoading(true)
    }
  }, [selectedAudience])

  return (<HomeSection name="pmf-score">
    <h2>PMF Score</h2>
    {dataError && <p className="error">{dataError}</p>}
    {!dataError && loading && <p>Loading...</p>}
    {!dataError && !loading &&
      <>
        <p className="score-value">{Math.round(10 * score) / 10}%</p>
        <ScoreChart data={chartableData(scoreResults)}></ScoreChart>
        <ul>
          {sortFeel(scoreResults).map((row, i) => (
            <li key={i}>
              <div className={`color-box ${row.feel.toLowerCase().replace(' ', '-')}`}></div>
              {row.feel}:
              <span className="percent">{Math.round(10 * row.percent) / 10}%</span>
            </li>
          ))}
        </ul>
      </>
    }
  </HomeSection>)
}

export default HomeScore
