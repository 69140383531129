import React from 'react'

const C = ({
  draggableProps,
  dragHandleProps,
  forwardRef,
  forwardStyle,
  step
}) => {
  return (
    <div
      className="step-item"
      ref={forwardRef}{...draggableProps}
      {...dragHandleProps} style={forwardStyle}
    >
      {step.name} [{step.type}]
    </div>
  )
}

export default C
