import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Col, Container, Row } from 'reactstrap'
import { queryRun } from '../../api/query'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const C = () => {
  const audienceAll = useSelector((state) => state.homeControl.audienceAll)
  const [loading, setLoading] = useState(false)
  const [locked, setLocked] = useState(false)
  const [audience, setOccupation] = useState(false)
  const id = useParams().id
  const [row, setRow] = useState(false)
  const [error, setError] = useState(null)

  const runQuery = async () => {
    const query = `SELECT * FROM \`feedback\` WHERE id = '${id}'`
    console.log(query)
    setLoading(true)
    setError(null)

    const response = await queryRun(query)
    console.log(response)
    if (response.error) {
      setError(response.error)
    } else {
      setRow(response.results[0])
    }

    setLoading(false)
  }

  const update = async () => {
    if (audience === 'Select one') {
      toast.error('Please select an audience')
      return
    }

    const query = `UPDATE \`feedback\` SET audience = '${audience}' WHERE id = '${id}'`
    console.log(query)
    setLoading(true)
    setError(null)

    const response = await queryRun(query)
    console.log(response)
    if (response.error) {
      setError(response.error)
    } else {
      setLocked(true)
      toast.success('Occupation updated')
    }

    setLoading(false)
  }

  useEffect(() => {
    runQuery()
  }, [id])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Fix audience</h1>
        </Col>
      </Row>

      {error &&
        <Row className="py-4 error">
          Error: {error}
        </Row>
      }

      {loading &&
        <Row className="py-4">
          Loading...
        </Row>
      }

      {row &&
        <Row className="py-4">
          <table>
            <tbody>
              <tr>
                <th>Created At</th>
                <td>{row.date.value}</td>
              </tr>
              <tr>
                <th>Feel</th>
                <td>{row.feel}</td>
              </tr>
              <tr>
                <th>Benefit</th>
                <td>{row.benefit}</td>
              </tr>
              <tr>
                <th>Missing</th>
                <td>{row.missing}</td>
              </tr>
              <tr>
                <th>Occupation (user input)</th>
                <td>{row.audience}</td>
              </tr>

              {!locked &&
                <tr>
                  <th>Occupation (AI interpretation or fixed by admin)</th>
                  <td>{row.audience}</td>
                </tr>
              }

              <tr>
                <th>Set Occupation</th>
                <td>
                  <select
                    className='input-container__input'
                    onChange={e => setOccupation(e.target.value)}
                  >
                    {['Select one', ..._.without(audienceAll, 'all')].map((audience) => (
                      <option
                        value={audience}
                        key={audience}
                        >{audience}</option>
                    ))}
                  </select>
                  <Button disabled={locked} onClick={() => update()}>Save</Button>
                </td>
              </tr>

            </tbody>
          </table>
        </Row>
      }

    </Container>
  )
}

export default C
