export default [
  {
    name: 'English EN',
    always: true,
    code: 'en'
  },
  {
    name: 'German DE',
    always: false,
    code: 'de'
  },
  {
    name: 'Spanish ES',
    always: false,
    code: 'es'
  },
  {
    name: 'French FR',
    always: false,
    code: 'fr'
  }
]
