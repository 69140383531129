import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import Title from '../../components/Title'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Loader from '../../components/Loader'
import './index.scss'
import { signInUser } from '../../core/reducers/user'
import { emailSchema, passwordSchema } from '../../utils/schemas'
/*
import Hr from '../../components/hr'
import ContinueWithGoogle from './google'
*/
import { Link, useNavigate } from 'react-router-dom'
import { getMixpanel } from '../../api/mixpanel'

const mixpanel = getMixpanel()

const schema = Joi.object({
  email: emailSchema(),
  password: passwordSchema()
})

const LoginPage = memo(() => {
  const navigate = useNavigate()
  const authUser = useSelector((state) => state.rootReducers.authUser)

  const {
    handleSubmit, formState: { errors }, setValue
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: joiResolver(schema)
  })
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.rootReducers.isComponentLoading)

  const onSubmit = async (values) => {
    const { email, password } = values
    await dispatch(signInUser({
      email: email.toLowerCase(),
      password
    }))

    if (authUser.uid) {
      mixpanel.track('Login A20', { method: 'email' })
      navigate('/dashboard')
    }
  }

  return (
    <div className='login'>
      <div className='login__container'>
        <Title value='Login' />
        <form className='login__form' onSubmit={handleSubmit(onSubmit)}>
          <Input
            type='text'
            name='email'
            label='Email'
            placeholder='Enter your email'
            onChange={(e) => setValue('email', e.target.value)}
            error={errors?.email?.message}
            disabled={isLoading}
          />
          <Input
            type='password'
            name='password'
            label='Password'
            placeholder='Enter your password'
            onChange={(e) => setValue('password', e.target.value)}
            error={errors?.password?.message}
            disabled={isLoading}
          />
          {
            isLoading
              ? <Loader />
              : (
                <>
                  <Button
                    loading={isLoading}
                    text='Login'
                    type='submit'
                  />
                  <center>
                    <Link to='/forgot-password' className='login__forgot-password'>Forgot password</Link>
                  </center>
                </>
                )
        }
        </form>
        { /*
        <>
        <Hr>Or</Hr>
        <ContinueWithGoogle text="Login with Google" />
        <div className="login-signup-cross-link">
        <Link to="/signup">Signup</Link>
        </div>
        </>
      */ }
      </div>
    </div>
  )
})

LoginPage.displayName = LoginPage

export default LoginPage
