let cache = {}

const cacheClear = () => {
  cache = {}
}

const cacheGetItem = (key) => {
  if (!cache[key]) return null

  if (cache[key].expires < Date.now()) {
    cacheDeleteItem(key)
    return null
  }

  return cache[key]
}

const cacheGetItemData = (key) => {
  const item = cacheGetItem(key)

  if (item === null) {
    return null
  }

  return item.value
}

const cacheSetItem = (key, value) => {
  const data = { value }

  if (!data.expires) {
    data.expires = Date.now() + 1000 * 60 * 60
  }

  cache[key] = data
}

const cacheDeleteItem = (key) => {
  delete (cache[key])
}

export {
  cache,
  cacheClear,
  cacheDeleteItem,
  cacheGetItem,
  cacheGetItemData,
  cacheSetItem
}
