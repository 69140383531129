import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Button, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { formTemplateSubscribe } from '../../api/form-templates'
import Loader from '../../components/Loader'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { accountFormCreate, accountFormCreated } from '../../api/accounts/forms'

const customizeSteps = (steps, account) => {
  const newSteps = steps.map((step) => {
    if (step.question) {
      return {
        ...step,
        question: step.question.replace('Earkick', account.metadata.name)
      }
    }

    return step
  })

  return newSteps
}

const FormManagerSelectTemplate = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [formName, setFormName] = useState('')
  const account = useSelector(state => state.accountReducers.account)

  useEffect(() => {
    const unsubscribe = formTemplateSubscribe((templates) => {
      setTemplates(templates)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const createForm = async () => {
    const formId = uuid()
    await accountFormCreate(account.id, formId, {
      name: formName,
      templateId: selectedTemplate.id,
      published: false,
      deleted: false,
      steps: customizeSteps(selectedTemplate.metadata.steps, account)
    })

    await accountFormCreated(account.id, formId)

    navigate(`/dashboard/form-manager/edit/${formId}`)
  }

  return (
    <Container>
      <Row>
        <Col>
        </Col>
        <Col></Col>
        <Col>

        </Col>
      </Row>
      <Row>
        {!loading && templates.map((template) => (
          <div key={template.id} onClick={() => setSelectedTemplate(template)}>
            <h1>{template.metadata.name}</h1>
          </div>
        ))}
        {loading && <Loader></Loader>}
      </Row>
      <Modal isOpen={selectedTemplate !== null}>
        <ModalHeader toggle={() => setSelectedTemplate(null)}>Create Form</ModalHeader>
        <ModalBody>
          <p>Name your form</p>
          <Input type="text" placeholder="Form Name" onChange={(e) => setFormName(e.target.value)}></Input>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => createForm()}>Create</Button>{' '}
        </ModalFooter>
      </Modal>
    </Container>
  )
}

export default FormManagerSelectTemplate
