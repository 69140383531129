import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  responsive: true,
  cutout: 65,
  plugins: {
    legend: {
      display: false
    }
  }
}

const C = ({ data, title }) => {
  const { labels } = data
  const datasets = [{
    data: data.values,
    backgroundColor: [
      '#DCDCDC',
      '#606872',
      '#242529'
    ],
    borderColor: [
      '#DCDCDC',
      '#606872',
      '#242529'
    ],
    borderWidth: 1
  }]

  return (
    <div className="showcase-chart">
      <h3>{ title }</h3>
      <div className="chart-area">
        <Doughnut data={{ labels, datasets }} options={options} />
      </div>
    </div>
  )
}

export default C
