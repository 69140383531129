import React, { useEffect, useState } from 'react'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router'
import { Button, Col, Container, Input, Label, Row } from 'reactstrap'
import Loader from '../../components/Loader'
import { auth } from '../../api/firebase'
import { useDispatch, useSelector } from 'react-redux'
import { signInUser } from '../../core/reducers/user'

const OnboardingIndex = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userAuth = useSelector((state) => state.userReducers.authUser)
  const user = useSelector((state) => state.userReducers.user)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const createAccount = async () => {
    if (email === '') {
      setError('Email is required')
      return
    }

    if (password === '') {
      setError('Password is required')
      return
    }

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (!email.match(validRegex)) {
      setError('Email is not valid')
      return
    }

    setLoading(true)
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setLoading(false)
        dispatch(signInUser({
          email: email.toLowerCase(),
          password
        }))
        navigate('/onboarding/project')
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        if (error.code === 'auth/email-already-in-use') {
          setError('Email already in use')
        } else {
          setError(error.message)
        }
      })
  }

  useEffect(() => {
    setError(false)
  }, [email, password])

  useEffect(() => {
    console.log(userAuth)
    console.log(user)
    if (userAuth && !userAuth.isAnonymous) {
      if (user.exist) {
        // Case that project & user record exists
        navigate('/onboarding/audience')
      } else {
        // Case that we need to create project and user record
        navigate('/onboarding/project')
      }
    }
  }, [userAuth])

  return <Container className="onboarding">
    <Row>
      <h1>Let&apos;s get started</h1>
      <p>During this onboarding, you will create your first Product Market Fit user survey form.</p>
    </Row>

    <Row>
      <Col></Col>
      <Col>
        {error &&
          <p className="error">{error}</p>
        }
        <Row>
          <Label>Email</Label>
          <br />
          <Input type="text" name="email" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} />
        </Row>
        <Row>
          <Label>Password</Label>
          <Input type="password" name="email" placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)} />
        </Row>
        <Row>
          {!loading &&
            <center>
              <Button color="primary" onClick={() => createAccount()}>Get started!</Button>
            </center>
          }
          {loading &&
            <Loader />
          }
        </Row>
      </Col>
      <Col></Col>
    </Row>
  </Container>
}

export default OnboardingIndex
