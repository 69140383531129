import React, { memo, useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import logo from '../../../images/logo.svg'
// import { ReactComponent as SettingsIcon } from '../../../images/settings.svg'
import { ReactComponent as DashboardIcon } from '../../../images/dashboard-icon.svg'
import { ReactComponent as DashboardIconSelected } from '../../../images/dashboard-icon-selected.svg'
import { ReactComponent as AudienceIcon } from '../../../images/person.3.sequence.fill.svg'

const Dive = () => (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 18.7334C14.9658 18.7334 19.0791 14.6289 19.0791 9.65427C19.0791 4.68845 14.9658 0.575165 9.99121 0.575165C5.02539 0.575165 0.920898 4.68845 0.920898 9.65427C0.920898 14.6289 5.03418 18.7334 10 18.7334ZM10 16.9492C5.95703 16.9492 2.71387 13.6972 2.71387 9.65427C2.71387 5.6113 5.94824 2.36813 9.99121 2.36813C14.0342 2.36813 17.2861 5.6113 17.2949 9.65427C17.2949 13.6972 14.043 16.9492 10 16.9492ZM5.31543 12.8623C5.31543 13.2929 5.60547 13.583 6.03613 13.583H14.1309C14.4121 13.583 14.6406 13.3545 14.6406 13.0732C14.6406 12.7832 14.4121 12.5547 14.1309 12.5547H6.37012C6.35254 12.5547 6.34375 12.5459 6.34375 12.5283V10.7881L7.99609 9.12692C8.04004 9.07419 8.08398 9.07419 8.13672 9.12692L9.58691 10.6299C9.80664 10.8408 10.0176 10.9375 10.2637 10.9375C10.5098 10.9375 10.7383 10.8408 10.9404 10.6299L12.5576 8.97751L13.1465 9.58395C13.375 9.80368 13.709 9.68063 13.7969 9.34665L14.4297 6.8945C14.5088 6.60446 14.2363 6.34079 13.9375 6.41989L11.4941 7.03513C11.1689 7.11423 11.0371 7.46579 11.2656 7.68552L11.8545 8.26559L10.334 9.81247C10.29 9.87399 10.2461 9.87399 10.2021 9.82126L8.72559 8.30075C8.53223 8.08981 8.3125 7.99313 8.06641 7.99313C7.81152 7.99313 7.5918 8.08981 7.38965 8.30075L6.34375 9.36423V5.99802C6.34375 5.71677 6.11523 5.47946 5.83398 5.47946C5.55273 5.47946 5.31543 5.71677 5.31543 5.99802V12.8623Z" fill="#FAECDF"/>
</svg>)

const DeepDive = () => (<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M4.0944 0H3.35234C3.04562 0 2.87539 0.158858 2.87539 0.40065V4.77698V4.83544C3.38793 4.64506 3.95386 4.4729 4.56849 4.3209V0.40065C4.56849 0.154716 4.36649 0 4.0944 0Z" fill="#FAECDF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.5699 4.15179C5.81559 4.1518 1.6019 5.37514 0.469167 7.18751C-0.174825 8.21789 -0.762035 13.3506 3.09709 16.6118C4.02686 17.0953 6.66592 17.9012 8.35298 17.3367C8.96099 17.1333 9.36493 16.6614 9.75931 16.2007C10.2516 15.6256 10.729 15.0678 11.5699 15.0713C12.4133 15.0748 12.8962 15.6355 13.3912 16.2104C13.7858 16.6686 14.1881 17.1358 14.7869 17.3367C16.5076 17.9142 19.126 17.1244 19.9974 16.6118C23.8295 13.7793 23.0523 7.52707 22.7612 7.18751C21.1301 5.28452 17.3242 4.15179 11.5699 4.15179ZM11.5334 5.58033C6.46265 5.58034 2.7481 6.42907 1.74993 7.8581C1.18244 8.67055 1.14431 13.3894 3.86288 15.4049C4.6822 15.7861 6.57521 16.4216 8.06185 15.9765C8.23333 15.8938 8.40468 15.6616 8.61717 15.3737C9.13849 14.6675 9.90744 13.6257 11.5334 13.6317C13.1055 13.6375 13.7691 14.472 14.3316 15.1793C14.5879 15.5016 14.8232 15.7975 15.114 15.9765C16.6303 16.4318 18.4374 15.809 19.2053 15.4049C21.5962 14.1464 21.9468 8.38787 21.4393 7.8581C20.002 6.35762 16.6041 5.58033 11.5334 5.58033Z" fill="#FAECDF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.87539 17.1217C3.26935 17.3123 3.87802 17.5383 4.57835 17.7212C4.70408 19.7775 6.01698 20.065 7.6677 20.1719H8.55401V19.9226C8.55401 19.7169 8.70092 19.57 8.9066 19.57H12.4619C12.6382 19.57 12.8145 19.7757 12.8145 19.9226V22.0969C12.8145 22.2732 12.6382 22.4201 12.4619 22.4201H8.9066C8.7303 22.4201 8.55401 22.2732 8.55401 22.0969V21.842H7.6677C6.06859 21.842 4.91283 21.6283 3.8823 20.4343C3.06314 19.4852 2.87539 18.6728 2.87539 17.386V17.1217Z" fill="#FAECDF"/>
</svg>
)

const DatabaseIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z" fill="#FAECDF" /></svg>)

const PeopleIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z" fill="#FAECDF" /></svg>)
const FormIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 0v64h64V96H64zm384 0H192v64H448V96zM64 224v64h64V224H64zm384 0H192v64H448V224zM64 352v64h64V352H64zm384 0H192v64H448V352z" fill="#FAECDF" /></svg>)

const Sidebar = memo(() => {
  const [menu, setMenu] = useState([])
  const { pathname } = useLocation()
  const user = useSelector((state) => state.userReducers.user)
  const account = useSelector((state) => state.accountReducers.account)

  useEffect(() => {
    const updateMenu = []
    updateMenu.push({
      path: '/dashboard',
      Icon: DashboardIconSelected,
      IconInactive: DashboardIcon,
      title: 'Home'
    },
    {
      path: '/dashboard/deep-dive',
      Icon: DeepDive,
      IconInactive: DeepDive,
      title: 'Deep dive'
    },
    {
      path: '/dashboard/raw-data',
      Icon: Dive,
      IconInactive: Dive,
      title: 'Raw Data'
    },
    {
      path: '/dashboard/query',
      Icon: DatabaseIcon,
      IconInactive: DatabaseIcon,
      title: 'Query'
    },
    {
      path: '/dashboard/audience-manager',
      Icon: PeopleIcon,
      IconInactive: PeopleIcon,
      title: 'Audience'
    },
    {
      path: '/dashboard/form-manager',
      Icon: FormIcon,
      IconInactive: FormIcon,
      title: 'Forms'
    })

    if ((account && account.metadata.showActiveUsers) || (user && user.metadata.showActiveUsers)) {
      updateMenu.push({
        path: '/dashboard/active-users',
        Icon: AudienceIcon,
        IconInactive: AudienceIcon,
        title: 'Active Users'
      })
    }

    setMenu(updateMenu)
  }, [account, user])

  return (
    <div className='sidebar'>
      <div className='sidebar__container'>
        <div className='logo'>
          <img src={logo} alt='logo' className='logo__img' />
        </div>
        <ul className='sidebar__menu'>
          {
            menu?.map(({ Icon, IconInactive, path, title }) => (
              <li className={`sidebar__menu-item ${path === pathname ? 'sidebar__menu-item_active' : ''}`} key={uuid()}>
                <Link to={path} className='sidebar__menu-item-link'>
                  {path === pathname &&
                    <Icon />
                  }
                  {path !== pathname && IconInactive &&
                    <IconInactive />
                  }
                  {title}
                </Link>
              </li>
            ))
          }
        </ul>
      </div>

      { /*
      <div className='sidebar__bottom'>
        <div className={`sidebar__bottom-item ${pathname === '/admin/settings' ? 'sidebar__bottom-item_active' : ''}`}>
          <Link to='/admin/settings' className='sidebar__bottom-item-link'>
            <SettingsIcon />
            Settings
          </Link>
        </div>
      </div>
      */ }
    </div>
  )
})

Sidebar.displayName = 'Sidebar'

export default Sidebar
