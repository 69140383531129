import {
  onAuthStateChanged, signOut, signInWithEmailAndPassword
} from 'firebase/auth'
import { auth } from './firebase'

export const checkUserAuth = () => new Promise((resolve, reject) => {
  onAuthStateChanged(auth, (currentUser) => {
    // console.log('onAuthStateChanged', currentUser)
    if (!currentUser) {
      reject(new Error('No user'))
    } else {
      resolve(currentUser)
    }
  })
})

export const signOutUser = () => new Promise((resolve, reject) => {
  signOut(auth).then(() => {
    console.log('signOut')
    resolve()
  }).catch((error) => {
    reject(error)
  })
})

export const signInUser = (email, password) => new Promise((resolve, reject) => {
  signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
    const { user } = userCredential
    resolve(user)
  }).catch((error) => {
    reject(error)
  })
})
