import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  audience: [],
  audienceAll: [],
  audienceScore: false
}

export const controls = createSlice({
  name: 'Home Control',
  initialState,
  reducers: {
    setAudience: (state, action) => ({
      ...state,
      audience: action.payload
    }),
    setAudienceAll: (state, action) => ({
      ...state,
      audienceAll: action.payload
    }),
    setAudienceScore: (state, action) => ({
      ...state,
      audienceScore: action.payload
    }),
    unsetAudience: (state) => ({
      ...state,
      account: false
    })
  }
})

// for redux (sync)
export const {
  setAudience,
  setAudienceAll,
  setAudienceScore,
  unsetAudience
} = controls.actions

export default controls.reducer
