import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { accountFormSubscribe } from '../../../api/accounts/forms'
import Loader from '../../../components/Loader'
import { useParams } from 'react-router'

const FormManagerEdit = () => {
  const account = useSelector(state => state.accountReducers.account)
  const [form, setForm] = useState(null)
  const [loading, setLoading] = useState(true)
  const formId = useParams().id

  useEffect(() => {
    const unsubscribe = accountFormSubscribe(account.id, formId, (formData) => {
      setForm(formData)
      setLoading(false)
    })

    return unsubscribe
  }, [account, formId])

  return (
    <Container>
      <Row>
        <Col>
        </Col>
        <Col></Col>
        <Col>

        </Col>
      </Row>
        {!loading && form &&
          <Row>
            <h1>{form.metadata.name}</h1>

            <div className="mt-3">Test URL: <a href={`https://pmf-engine.com/form/${account.id}/${formId}/test/test`} target="_blank" rel="noreferrer">{`https://pmf-engine.com/form/${account.id}/${formId}/test/test`}</a></div>

            <div className="mt-3">
              slackWebhook: {form.metadata.slackWebhook || 'Not set'}
            </div>

            <div className="mt-3">
              <p>thank you:</p>
              <ul>
                <li>title: {form.metadata.thankyou.title}</li>
                <li>subtitle: {form.metadata.thankyou.subtitle}</li>
              </ul>
            </div>

            <div className="mt-3">
              tableName: {form.metadata.tableName}
            </div>
          </Row>
        }
        {loading && <Row><Loader></Loader></Row>}
    </Container>
  )
}

export default FormManagerEdit
