import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
// import Loader from '../../../components/Loader'
import { partialCleanup, submit } from '../../../api/accounts/forms'
import { useParams } from 'react-router'
import { t } from '../../../utils/translate'
// import PMF from 'pmf-engine-sdk'

const FormStepThankYou = ({ results, title, processId, subtitle }) => {
  const { accountId, formId, userId, userAgent } = useParams()
  //  const [error, setError] = useState(false)
  //  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const data = {}
    results.forEach(result => {
      data[result.name] = result.value
    })

    if (userId) {
      data.user_id = userId
    }

    if (userAgent) {
      data.user_agent = userAgent
    }

    submit(accountId, formId, processId, data).then(async response => {
      console.log(response)
      if (response.error) {
        // setError(response.error)
      } else {
        partialCleanup(processId)
        // const command = await new PMF(accountId, { userId }).getCommand()
        // console.log(command)
      }

      // setLoading(false)
    })
  }, [results])

  return (
    <div className="step-inputs">
      <Row className="thankyou">
        <Col>
          <h1>{t(title)}</h1>
          <h3>{t(subtitle)}</h3>
          { /* loading && <Loader />}
          {!loading && !error && <p>All done :)</p>}
    {!loading && error && <p>Something went wrong: ${error}</p> */}
        </Col>
      </Row>
    </div>
  )
}

export default FormStepThankYou
