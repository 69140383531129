import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router'
import hexToRgba from 'hex-to-rgba'
import { Button, Col, Container, Input, Label, Row } from 'reactstrap'
import ColorRow from './color-row'
import { formTemplateGet } from '../../api/form-templates'
import StepCounter from '../../components/step-counter'
import FormPreview from './form-preview'
import './index.scss'
import { useSelector } from 'react-redux'
import { accountFormCreate, accountFormCreated, accountFormUpdate, accountFormsSubscribe } from '../../api/accounts/forms'

const lightenBrandColor = 0.5

function shadeColor (color, percent) {
  return hexToRgba(color, percent)
}

const customizeSteps = (steps, account) => {
  const newSteps = steps.map((step) => {
    if (step.question) {
      return {
        ...step,
        question: step.question.replace('Earkick', account.metadata.name)
      }
    }

    return step
  })

  return newSteps
}

const C = () => {
  const navigate = useNavigate()
  const [form, setForm] = useState(false)
  const [formPreview, setPreview] = useState(false)
  const account = useSelector(state => state.accountReducers.account)
  const [backgroundColor, setBackgroundColor] = useState('')
  const [textColor, setTextColor] = useState('')
  const [brandColor, setBrandColor] = useState('')
  const [loading, setLoading] = useState(true)

  const createForm = async () => {
    console.log('createForm')
    console.log(account)
    const formId = uuid()
    const selectedTemplate = await formTemplateGet('1')

    console.log(selectedTemplate)

    if (!selectedTemplate) {
      throw new Error('Template not found')
    }

    await accountFormCreate(account.id, formId, {
      name: 'Default',
      ...selectedTemplate.metadata,
      templateId: selectedTemplate.id,
      description: '',
      published: false,
      deleted: false,
      coreEventName: 'default',
      steps: customizeSteps(selectedTemplate.metadata.steps, account)
    })

    await accountFormCreated(account.id, formId)
  }

  useEffect(() => {
    if (account) {
      const unsubscribe = accountFormsSubscribe(account.id, (forms) => {
        if (forms.length === 0) {
          createForm()
          return
        }

        const formData = forms[0]

        setForm(formData)

        setBackgroundColor(formData.metadata.colors?.background || '')
        setTextColor(formData.metadata.colors?.text || '')
        setBrandColor(formData.metadata.colors?.brand || '')
        setLoading(false)
      })

      return unsubscribe
    }
  }, [account])

  const updateBackgroundColor = (value) => {
    setBackgroundColor(value)
    accountFormUpdate(account.id, form.id, {
      colors:
      {
        background: value,
        text: textColor,
        brand: brandColor,
        brandLight: shadeColor(brandColor, lightenBrandColor)
      }
    })
  }

  const updateTextColor = (value) => {
    setTextColor(value)
    accountFormUpdate(account.id, form.id, {
      colors:
      {
        background: backgroundColor,
        text: value,
        brand: brandColor,
        brandLight: shadeColor(brandColor, lightenBrandColor)
      }
    })
  }

  const updateBrandColor = (value) => {
    setBrandColor(value)
    accountFormUpdate(account.id, form.id, {
      colors: {
        background: backgroundColor,
        text: textColor,
        brand: value,
        brandLight: shadeColor(value, lightenBrandColor)
      }
    })
  }

  const setEmailSkipped = (value) => {
    accountFormUpdate(account.id, form.id, {
      emailQuestionSkipped: !value
    })
  }

  return (<Container className="onboarding">
    <Row>
      <center>
        <StepCounter step={4} steps={5} />
      </center>
    </Row>
    <Row>
      <h1>Customize the PMF survey</h1>
      <p>You can customize the theme, font and background colors of your PMF survey in order to match with your corporate theme. This will be visible to your users.</p>
    </Row>

    {!loading &&
      <Row>
        <Col>
          <center>
            <ColorRow
              label="Theme color"
              defaultValue={brandColor}
              onChange={(value) => updateBrandColor(value)}
            />
          </center>
        </Col>

        <Col>
          <center>
            <ColorRow
              label="Font color"
              defaultValue={textColor}
              onChange={(value) => updateTextColor(value)}
            />
          </center>
        </Col>
        <Col>
          <center>
            <ColorRow
              label="Background color"
              defaultValue={backgroundColor}
              onChange={(value) => updateBackgroundColor(value)}
            />
          </center>
        </Col>
      </Row>
    }

    {!loading &&
      <Row>
        <Col></Col>
        <Col>
          <center>
            <Label>
              <Input type="checkbox" onChange={(e) => {
                setEmailSkipped(e.target.checked)
              }} checked={!form.metadata.emailQuestionSkipped}></Input>
            &nbsp;Ask users for their email</Label>
          </center>
        </Col>
        <Col></Col>
      </Row>
    }

    <Row>
      <Col></Col>
      <Col>
        <Row>
          <center>
            <a href="#" onClick={(e) => {
              e.preventDefault()
              setPreview(!formPreview)
            }}>Preview</a>
          </center>
        </Row>

        <Row>
          <center>
            <Button color="primary" onClick={() => navigate('/onboarding/sdk')}>Next</Button>
          </center>
        </Row>
      </Col>
      <Col></Col>
    </Row>
    <FormPreview
      accountId={account.id}
      form={form}
      toggle={() => setPreview(!formPreview)}
      formPreview={formPreview}
    />
  </Container>)
}

export default C
