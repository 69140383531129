import { v4 as uuid } from 'uuid'
import { create, getOne, remove, subscribeAll, update } from '../db'

const accountsCollectionName = 'accounts'
const collectionName = 'deep-dive-settings'

const deepDiveSettingCreate = (accountId, data) => {
  const id = uuid()

  create(`${accountsCollectionName}/${accountId}/${collectionName}`, id, {
    ...data,
    createdAt: new Date(),
    updatedAt: new Date()
  })

  return id
}

const deepDiveSettingDelete = async (accountId, id) => {
  await remove(`${accountsCollectionName}/${accountId}/${collectionName}`, id)
}

const deepDiveSettingGet = async (accountId, id) => {
  return await getOne(`${accountsCollectionName}/${accountId}/${collectionName}`, id)
}

const deepDiveSettingSubscribeAll = (accountId, mutationCallback) => subscribeAll(`${accountsCollectionName}/${accountId}/${collectionName}`, mutationCallback)

const deepDiveSettingUpdate = async (accountId, id, data) => {
  return update(`${accountsCollectionName}/${accountId}/${collectionName}`, id, data)
}

export {
  deepDiveSettingCreate,
  deepDiveSettingDelete,
  deepDiveSettingGet,
  deepDiveSettingSubscribeAll,
  deepDiveSettingUpdate
}
