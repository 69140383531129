import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'

import FormLayout from '../layouts/FormLayout'
import Form from '../modules/form'

const AccountRoutes = memo(() => (
  <Routes>
    <Route element={<FormLayout />}>
    <Route
      path=':accountId/:formId'
      element={(
        <Form />
      )}
    />
    <Route
      path=':accountId/:formId/:userId'
      element={(
        <Form />
      )}
    />
    <Route
      path=':accountId/:formId/:userId/:userAgent'
      element={(
        <Form />
      )}
    />

    </Route>
  </Routes>

))

AccountRoutes.displayName = 'FormRoutes'

export default AccountRoutes
