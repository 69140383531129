import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { t, language } from '../../../utils/translate'
import _ from 'lodash'

const FormStepAudience = ({ form, audiences, onSelect, defaultValue, onKeyPress, languages }) => {
  const [selected, setSelected] = useState(defaultValue)
  const [other, setOther] = useState(null)
  const inputReference = useRef(null)
  const [value, setValue] = useState(null)
  const [filteredAudiences, setFilteredAudiences] = useState(audiences)

  const setOption = (option) => {
    if (option === 'Other') {
      setOther(true)
      setTimeout(() => {
        inputReference.current.focus()
      }, 250)
    } else {
      setSelected(option)
      onSelect(option)
    }
  }

  useEffect(() => {
    if (!audiences || audiences.length === 0) {
      setOther(true)
    } else if (languages) {
      setFilteredAudiences(audiences.map(a => {
        if (typeof a === 'string') {
          return a
        }

        if (!a[language]) {
          return a.en
        }

        if (!_.find(languages, { code: language })) {
          return a.en
        }

        return a[language]
      }))

      setOther(null)
    }
  }, [languages, audiences])

  return (
    <div className="options">
      <style type="text/css">
      input {'{'}
        &::placeholder {'{'}
          {`color: ${form.metadata.colors.brandLight} !important;`}
          {'}'}
        {'}'}
      </style>
      {!other && filteredAudiences.map((option, i) => {
        const optionEn = typeof option === 'string' ? option : option.en
        return (
        <Row key={i}>
          <Col>
            <Button
              style={{
                borderColor: form.metadata.colors.brandLight,
                backgroundColor: form.metadata.colors.brandLight,
                color: form.metadata.colors.brand
              }}
              className={`btn btn-primary ${optionEn === selected ? 'option-selected' : ''}`}
              onClick={() => {
                setOption(optionEn)
              }}
            >{typeof option === 'string' ? option : option[language] || option.en}</Button>
          </Col>
        </Row>)
      })}
      {other &&
        <>
          <Row>
            <Col>
              <input
                ref={inputReference}
                style={{
                  backgroundColor: form.metadata.colors.background,
                  color: form.metadata.colors.brand,
                  borderColor: form.metadata.colors.brandLight
                }}
                autoFocus
                placeholder={t('Please describe yourself in one or two words')}
                type="text"
                onKeyUp={(e) => {
                  setValue(e.target.value)
                  onKeyPress(e.target.value)
                }}
                onChange={(e) => {
                  setValue(e.target.value)
                  onKeyPress(e.target.value)
                }}
                />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={{
                  borderColor: form.metadata.colors.brand,
                  backgroundColor: form.metadata.colors.brandLight,
                  color: form.metadata.colors.brand
                }}
                onClick={() => onSelect(value)}>ok</Button>
            </Col>
          </Row>
        </>
      }
    </div>
  )
}

export default FormStepAudience
