import React from 'react'
import './index.scss'

const ButtonIcon = ({ onClick, children, className }) => {
  return (<button type="button" onClick={onClick} className={['button-icon', className].join(' ')}>
      {children}
    </button>
  )
}

export default ButtonIcon
