import React, { memo, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  Navigate
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { userUpdateField } from '../api/users'
import { accountSubscribe } from '../api/accounts'
import { setAccount } from '../core/reducers/account'

const debug = false

const AccountGuard = ({
  children
}) => {
  const dispatch = useDispatch()
  const account = useSelector(state => state.accountReducers.account)

  if (!account) {
    return children
  }

  useEffect(() => {
    return accountSubscribe(account.id, (accountData) => {
      dispatch(setAccount(accountData))
    })
  }, [])

  return children
}

const UnregisteredUsersGuard = memo(({
  children,
  redirectTo
}) => {
  if (debug) {
    console.log('UnregisteredUsersGuard')
    console.log({ redirectTo })
  }

  const id = uuidv4()
  const authUser = useSelector((state) => state.userReducers.authUser)

  if (debug) {
    console.log(authUser ? `U ${id}: User is registered` : `U ${id}: User is not registered`, authUser)
    console.log(`U ${id}: redirectTo`, redirectTo)
  }

  return authUser ? children : (<Navigate to={redirectTo || window.location.pathname} />)
})

UnregisteredUsersGuard.displayName = 'UnregisteredUsersGuard'

const RegisteredUsersGuard = memo(({
  children,
  redirectTo = false
}) => {
  if (debug) {
    console.log('RegisteredUsersGuard')
    console.log({ redirectTo })
  }
  const id = uuidv4()
  const authUser = useSelector((state) => state.userReducers.authUser)
  const user = useSelector((state) => state.userReducers.user)

  if (debug) {
    console.log(authUser ? `R ${id}: User is registered` : `R ${id}: User is not registered`)
    console.log('R: redirectTo: ', redirectTo)
  }

  if (!authUser) {
    return children
  }

  if (!redirectTo) {
    if (debug) {
      console.log(`R ${id}: Redirecting to /dashboard`)
      console.log(`R ${id}: pathname`, window.location.pathname)
    }

    if (user && !user.metadata.onboarding) {
      userUpdateField({ field: 'onboarding', value: true })
      redirectTo = '/onboarding'
    } else if (window.location.pathname.indexOf('/dashboard') === 0) {
      redirectTo = window.location.pathname
    } else {
      redirectTo = '/dashboard'
    }

    return (<Navigate to={redirectTo} />)
  }

  return !redirectTo ? children : (<Navigate to={redirectTo} />)
})

RegisteredUsersGuard.displayName = 'RegisteredUsersGuard'

export {
  AccountGuard,
  RegisteredUsersGuard,
  UnregisteredUsersGuard
}
