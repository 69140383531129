import React, { memo, Suspense, useEffect } from 'react'
import {
  Routes,
  Route,
  useNavigate
//  Navigate
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FormRoutes from './form'
import DashboardRoutes from './dashboard'
import OnboardingRoutes from './onboarding'
import LoginPage from '../modules/auth/login'
// import SignupPage from '../modules/auth/signup'
import ForgotPassword from '../modules/auth/forgot-password'
import GuestLayout from '../layouts/GuestLayout'
import LandingPage from '../modules/landing-page'

import {
  RegisteredUsersGuard
} from './guards'
import { signOutUser } from '../core/reducers/user'
import { accountSubscribe } from '../api/accounts'
import { setAccount } from '../core/reducers/account'

const LogoutComponent = memo(() => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(signOutUser())
    navigate('/login')
  }, [])

  return (<>Logging out...</>)
})

LogoutComponent.displayName = 'LogoutComponent'

const Routing = memo(() => {
  const user = useSelector((state) => state.userReducers.user)
  const accountId = user?.metadata?.activeAccount || false
  const dispatch = useDispatch()

  useEffect(() => {
    if (accountId) {
      const unsubscribe = () => {}

      const subscribe = async () => {
        return await accountSubscribe(accountId, data => {
          dispatch(setAccount(data))
        })
      }

      subscribe().then(f => unsubscribe)

      return unsubscribe
    }
  }, [user, accountId])

  return (
    <Suspense className='main-container' fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/dashboard/*" element={<DashboardRoutes />}></Route>
        <Route path="/onboarding/*" element={<OnboardingRoutes />}></Route>
        <Route path="/form/*" element={<FormRoutes />}></Route>
        <Route element={<GuestLayout />}>
          <Route
            path='/login'
            element={(
              <RegisteredUsersGuard>
                <LoginPage />
              </RegisteredUsersGuard>
            )}
          />
          <Route
            path='/forgot-password'
            element={(
              <RegisteredUsersGuard>
                <ForgotPassword />
              </RegisteredUsersGuard>
            )}
          />
          { /*
          <Route
          path='/signup'
          element={(
            <RegisteredUsersGuard>
            <SignupPage />
            </RegisteredUsersGuard>
            )}
            />
          */ }
        </Route>
        <Route element={<GuestLayout />}>
          <Route
            path='/logout'
            element={<LogoutComponent />}
          />

        </Route>
        <Route path="/" element={<LandingPage />} />
        { /*
          <Route path='/' element={<Navigate to='/login' />} />
        */}
        <Route path='/*' element={<GuestLayout />}>
          <Route path="*" element={<p>404</p>} />
        </Route>

      </Routes>
    </Suspense>
  )
})

Routing.displayName = 'Routing'

export default Routing
