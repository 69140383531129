import { v4 as uuid } from 'uuid'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Input, Row } from 'reactstrap'
import { defaultSettings } from './default-settings'
import { deepDiveSettingGet, deepDiveSettingUpdate } from '../../api/accounts/deep-dive-settings'
import { accountUpdate } from '../../api/accounts'
import { useNavigate, useParams } from 'react-router'
import { ClickableInput } from '../../components/ClickableInput'
import { useSelector, useDispatch } from 'react-redux'
import { checkUser } from '../../core/reducers/user'

const models = [
  'gpt-4-0125-preview', 'gpt-4-0613', 'gpt-3.5-turbo', 'gpt-3.5-turbo-0613'
]

const actionOptions = ['Benefit', 'Missing']

const feelOptions = [
  'Very disappointed', 'Somewhat disappointed', 'Not disappointed'
]

const EditSettingsComponent = () => {
  const account = useSelector(state => state.accountReducers.account)
  const id = useParams().id
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [setting, setSetting] = useState(defaultSettings)

  useEffect(() => {
    if (id !== 'default') {
      deepDiveSettingGet(account.id, id).then((data) => {
        setSetting(data || defaultSettings)
      })
    }
  }, [id])

  const onSave = async () => {
    const saveId = id !== 'default' ? id : uuid()

    await deepDiveSettingUpdate(account.id, saveId, setting.metadata)
    await accountUpdate(account.id, { settingSelected: saveId })
    dispatch(checkUser())

    navigate('/dashboard/deep-dive')
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <ClickableInput
            elementType="h1"
            value={setting.metadata.name}
            placeholder="Default"
            onChange={value => setSetting({ ...setting, metadata: { ...setting.metadata, name: value } })}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col>
          <h4>Feel</h4>
          <select
            value={setting.metadata.feel}
            className='input-container__input'
            onChange={e => setSetting({ ...setting, metadata: { ...setting.metadata, feel: e.target.value } })}
          >
            {feelOptions.map((option) => (
              <option
                value={option}
                key={option}
              >{option}</option>
            ))}
          </select>
        </Col>
        <Col>
          <h4>Dimension</h4>
          <select
            className='input-container__input'
            value={setting.metadata.action}
            onChange={e => setSetting({ ...setting, metadata: { ...setting.metadata, action: e.target.value } })}
          >
            {actionOptions.map((option) => (
              <option
                value={option}
                key={option}
              >{option}</option>
            ))}
          </select>
        </Col>
      </Row>

      <Row className="pt-4">
        <Col>
          <h4>System message</h4>
          <Input
            type="textarea"
            onChange={e => setSetting({ ...setting, metadata: { ...setting.metadata, systemMessage: e.target.value } })}
            placeholder="System message"
            value={setting.metadata.systemMessage}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col>
          <h4>Model name</h4>
          <select
            className='input-container__input'
            onChange={e => setSetting({ ...setting, metadata: { ...setting.metadata, model: e.target.value } })}
            value={setting.metadata.model}
          >
            {models.map((option) => (
              <option
                value={option}
                key={option}
                >{option}</option>
            ))}
          </select>
        </Col>

        <Col>
          <h4>Temperature</h4>
          <Input
           type="number"
            onChange={e => setSetting({ ...setting, metadata: { ...setting.metadata, temperature: parseFloat(e.target.value) } })}
            placeholder="Temperature"
            value={setting.metadata.temperature} />
        </Col>
        <Col>
          <h4>Max tokens</h4>
          <Input
            type="number"
            onChange={e => setSetting({ ...setting, metadata: { ...setting.metadata, maxTokens: parseInt(e.target.value) } })}
            placeholder="Max tokens"
            value={setting.metadata.maxTokens}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col>
          <Row>
            <Col>
              <Button onClick={() => navigate('/dashboard/deep-dive')}>Cancel</Button>
            </Col>
            <Col>
              <Button position="right" color="primary" onClick={() => onSave()}>Save</Button>
            </Col>
          </Row>
        </Col>
        <Col>
        </Col>
      </Row>

    </Container>
  )
}

export default EditSettingsComponent
