import React, { } from 'react'
import _ from 'lodash'
import { Input } from 'reactstrap'

const FormStepDropdown = ({ options, onSelect, defaultValue }) => {
  return (
    <div className="select">
      <Input
        type="select"
        options={_.map(options, x => ({ name: x, value: x }))}
        onChange={e => {
          onSelect(e.target.value)
        }}
        defaultValue={defaultValue}
      >
        <option>-- Select one --</option>
        {options.map((option, i) => (
          <option key={i}>{option}</option>
        ))}
      </Input>

    </div>
  )
}

export default FormStepDropdown
