import React, { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet, useNavigate } from 'react-router'
import { checkUser } from '../../core/reducers/user'
import Loader from '../../components/Loader'
import Sidebar from './components/sidebar'
import './index.scss'
import Header from './components/header'

const AdminLayout = memo(() => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoading = useSelector((state) => state.rootReducers.isLoading)
  const user = useSelector((state) => state.userReducers.authUser)

  useEffect(() => {
    dispatch(checkUser())
  }, [])

  const signOutHandle = async () => {
    navigate('/logout')
  }

  useEffect(() => {
    console.log('user change detected', user)
  }, [])

  return (
    <div className='admin-layout'>
      <Sidebar />
      <div className='admin-layout__container'>
        <Header signOutHandle={signOutHandle} />

        <div className='admin-layout__content'>
          {
            isLoading
              ? (<Loader />)
              : (
              <>
                <Outlet />
              </>
                )
          }
        </div>
      </div>
    </div>
  )
})

AdminLayout.displayName = 'AdminLayout'

export default AdminLayout
