import React, { useEffect, useState } from 'react'
import StepCounter from '../../components/step-counter'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { ReactComponent as CopySolid } from '../../images/copy-solid.svg'
import { ReactComponent as Javascript } from '../../images/javascript.svg'
import { ReactComponent as Apple } from '../../images/apple.svg'
import { ReactComponent as Android } from '../../images/android.svg'

import './index.scss'
import { userUpdateField } from '../../api/users'
import { accountFormsSubscribe } from '../../api/accounts/forms'
import { checkUser } from '../../core/reducers/user'
import Loader from '../../components/Loader'
import { accountSubscribe } from '../../api/accounts'

const C = ({ extended }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [language, setLanguage] = useState('js')
  const [recordEvent, setRecordEvent] = useState('default')
  const [getCommand, setGetCommand] = useState(false)
  const [coreEventName, setCoreEventName] = useState('default')
  // const [delay, setDelay] = useState('2 week')
  // const [occurences, setOccurences] = useState(3)
  const [, setDelay] = useState('2 week')
  const [, setOccurences] = useState(3)
  const [form, setForm] = useState(false)
  const [account, setAccount] = useState(useSelector(state => state.accountReducers.account))
  const user = useSelector(state => state.userReducers.user)
  const [codePreview, setCodePreview] = useState(false)

  useEffect(() => {
    generate(language)
  }, [])

  const open = (lang) => {
    setLanguage(lang)
    generate(lang)
    setCodePreview(true)
  }

  const toggle = () => {
    setCodePreview(false)
  }

  const copyCode = (code) => {
    navigator.clipboard.writeText(code)
    toast.info('Code copied to clipboard')
  }

  useEffect(() => {
    if (account) {
      if (account.metadata.submissionsCount > 0) {
        navigate('/dashboard')
        return () => {}
      }

      const unsubscribe = accountFormsSubscribe(account.id, (forms) => {
        const formData = forms[0]

        setForm(formData)
      })

      return unsubscribe
    }

    if (!user.metadata.onboarding) {
      userUpdateField({ field: 'onboarding', value: true })
      dispatch(checkUser()) // reload user data in state
    }
  }, [account])

  useEffect(() => {
    return accountSubscribe(account.id, (accountData) => {
      setAccount(accountData)
    })
  }, [])

  const generate = (lang) => {
    let re, gc

    if (lang === 'js') {
      re = `const Pmf = require('pmf-engine-sdk');
const pmf = new Pmf('${account.id}');

// userId is an identifier of your user. It can be email, id, or anything else that will be the same string that identifies user.
pmf.identify(userId);

await pmf.event('${coreEventName}');
`

      gc = `const Pmf = require('pmf-engine-sdk');
const pmf = new Pmf('${account.id}');
pmf.identify(userId);

const command = await pmf.getCommand({ eventName: '${coreEventName}' })

if(command && command.type === 'form') {
  // show form to the user
  window.location = command.url
}
`
    } else if (lang === 'ios') {
      re = `// 1. First you should

import pmf_engine_swift

// 2. Configure with your accountId and userId in the didFinishLaunchingWithOptions
//    userId is an identifier of your user. It can be email, id, or anything else that 
//    will be the same string that identifies user.

PMFEngine.default.configure(accountId: "${account.id}", userId: UUID().uuidString)


// 3. Track Event

PMFEngine.default.trackKeyEvent()
`

      gc = `// 4. Customize view

let popupView = PMFEnginePopupView()

popupView.emoji = UIImage(named: "smilling-panda")
popupView.title = "Pleeeeease! 🙏\n Help us to improve \nto help others!"
popupView.subTitle = "By answering a few simple questions."
popupView.confirmTitle = "Yes, happy to help!"
popupView.cancelTitle = "No, I don’t want to help!"

popupView.containerBackgroundColor = UIColor.white
popupView.closeButtonTitleColor = UIColor.lightGray
popupView.pmfButtonBackgroundColor = UIColor.purple
popupView.pmfButtonTitleColor = UIColor.white

popupView.confirmFont = UIFont.systemFont(ofSize: 17, weight: .bold)
popupView.cancelFont = UIFont.systemFont(ofSize: 14, weight: .semibold)


// 5. Show Popup if needed

PMFEngine.default.showPMFPopup(popupView: popupView, onViewController: topController)
`
    } else {
      re = `We are working on the ${lang} version of our sdk. It will be available soon.`
      gc = `We are working on the ${lang} version of our sdk. It will be available soon.`
    }

    setRecordEvent(re)
    setGetCommand(gc)
  }

  return <Container className="onboarding">
    <Row>
      <center>
        <StepCounter step={5} steps={5} />
      </center>
    </Row>
    <Row>
      <h1>Publish the PMF survey, we’re waiting for the first event
        <br />
        <br />
        {form && <a href={`https://pmf-engine.com/form/${account.id}/${form.id}/test/test`} target="_blank" rel="noreferrer">{`https://pmf-engine.com/form/${account.id}/${form.id}`}</a>}
      </h1>
    </Row>

    {account.metadata.submissionsCount === 0 &&
      <Row>
        <center>
          <Loader />
        </center>
        <br />
        <br />
        <br />
        <p>Waiting for first survey response</p>
      </Row>
    }

    <Row>
      <p>For even better results, add the PMF survey in your project and trigger it at the key event that you identified as the most important for your users to experience the benefit of your project.</p>
    </Row>

    {extended &&
      <>
        <Row>
          <Col>
            <Container>
              <Row>
                <label>Name of your event<br />(it can be anything)&nbsp;</label>
              </Row>
              <Row>
                <input type="text" placeholder="Your event name" onChange={(e) => setCoreEventName(e.target.value)} />
              </Row>
            </Container>
          </Col>

          <Col>
            <Container>
              <Row>
                <label>When to show the form? <br />format: [number] [day/week/month]</label>
              </Row>
              <Row>
                <input type="text" placeholder="2 week" onChange={(e) => setDelay(e.target.value)} />
              </Row>
            </Container>
          </Col>

          <Col>
            <Container>
              <Row>
                <label>Minimum number of core event occurrences to show the form</label>
              </Row>
              <Row>
                <input type="text" placeholder="3" onChange={(e) => setOccurences(e.target.value)} />
              </Row>
            </Container>
          </Col>
        </Row>

        <Row>
          <Col></Col>
          <Col><Button color="primary" onClick={() => generate(language)}>Generate</Button></Col>
          <Col></Col>
        </Row>
      </>
    }

    {recordEvent &&
      <>
        <Row className="sdk-buttons">
          <Col>
            <div className="sdk-button" onClick={() => open('js')}>
              <div className="logo">
                <Javascript />
              </div>
              <div className="text">
                JavaScript
              </div>
            </div>
          </Col>
          <Col>
            <div className="sdk-button" onClick={() => open('ios')}>
              <div className="logo">
                <Apple />
              </div>
              <div className="text">
                iOS Swift
              </div>
            </div>
          </Col>
          <Col>
            <div className="sdk-button" onClick={() => open('android')}>
              <div className="logo">
                <Android />
              </div>
              <div className="text">
                Android
              </div>
            </div>
          </Col>

        </Row>

        <Modal isOpen={codePreview} toggle={toggle} className="form-preview" size="lg">
          <ModalHeader toggle={toggle}>Form preview</ModalHeader>
          <ModalBody>

            {language === 'js' &&
              <Container>
                {recordEvent &&
                  <Row className="code-box">
                    <h3>Record core event</h3>
                    <pre className="code">
                      {recordEvent}
                    </pre>
                    <button
                      className="btn btn-primary copy-button"
                      onClick={() => copyCode(recordEvent)}>
                        <CopySolid title='Copy' />
                    </button>
                  </Row>
                }

                {getCommand &&
                  <Row className="code-box">
                    <h3>Check if form is ready to be shown</h3>
                    <pre className="code">
                      {getCommand}
                    </pre>
                    <button
                      className="btn btn-primary copy-button"
                      onClick={() => copyCode(getCommand)}>
                        <CopySolid title='Copy' />
                    </button>
                  </Row>
                }

                <Row>
                  Documentation: <a href="https://www.npmjs.com/package/pmf-engine-sdk" target="_blank" rel="noreferrer">https://www.npmjs.com/package/pmf-engine-sdk</a>
                </Row>
              </Container>
            }
            {language === 'ios' &&
              <Container>
                {recordEvent &&
                  <Row className="code-box">
                    <h3>Record core event</h3>
                    <pre className="code">
                      {recordEvent}
                    </pre>
                    <button
                      className="btn btn-primary copy-button"
                      onClick={() => copyCode(recordEvent)}>
                        <CopySolid title='Copy' />
                    </button>
                  </Row>
                }

                {getCommand &&
                  <Row className="code-box">
                    <h3>Check if form is ready to be shown</h3>
                    <pre className="code">
                      {getCommand}
                    </pre>
                    <button
                      className="btn btn-primary copy-button"
                      onClick={() => copyCode(getCommand)}>
                        <CopySolid title='Copy' />
                    </button>
                  </Row>
                }

                <Row>
                  Documentation: <a href="https://github.com/herbertbay/pmf-engine-swift" target="_blank" rel="noreferrer">https://github.com/herbertbay/pmf-engine-swift</a>
                </Row>
              </Container>
            }
          </ModalBody>
        </Modal>
      </>
    }

  </Container>
}

export default C
