import { getOne, subscribe } from './db'

const collectionName = 'form-templates'

const formTemplateSubscribe = (mutationCallback) => subscribe(collectionName, [['deleted', '==', false]], mutationCallback)
const formTemplateGet = (id) => getOne(collectionName, id)

export {
  formTemplateSubscribe,
  formTemplateGet
}
