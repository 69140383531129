export default {
  'How would you feel if you could <strong>no longer</strong> use {{accountName}}?': 'Wie würdest du dich fühlen, wenn du {{accountName}} <strong>nicht mehr</strong> benutzen könntest?',
  'Very disappointed': 'Sehr enttäuscht',
  'Somewhat disappointed': 'Etwas enttäuscht',
  'Not disappointed': 'Nicht enttäuscht',
  'What is the <strong>main benefit</strong> you receive from using {{accountName}}?': 'Was ist der <strong>Hauptnutzen</strong>, den du durch die Nutzung von {{accountName}} erhältst?',
  'Type your answer here...': 'Gib deine Antwort hier ein...',
  ok: 'ok',
  'How can we <strong>improve {{accountName}}</strong>  for you?': 'Wie können wir <strong>{{accountName}}</strong> für dich verbessern?',
  'What type of person do you think would <strong>benefit most</strong> from {{accountName}}': 'Welche Art von Person würden deiner Meinung nach am meisten von {{accountName}} <strong>profitieren</strong>',
  'Type your answer here': 'Gib deine Antwort hier ein',
  'What applies most to you?': 'Was trifft auf dich am meisten zu?',
  Students: 'Studenten',
  Tester: 'Tester',
  Other: 'Andere',
  'Your Email address': 'Deine E-Mail Adresse',
  'Can we contact you in case we have questions? No spam, promised!': 'Dürfen wir dich kontaktieren, wenn wir Fragen haben? Kein Spam, versprochen!',
  'name@example.com': 'name@example.com',
  'You are awesome!': 'Du bist großartig!',
  'Thank you for your time.': 'Ich danke dir für deine Zeit.'
}
