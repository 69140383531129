import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Button, Col, Container, Input, Row } from 'reactstrap'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import './index.scss'
import logo from '../../images/logo.svg'
import pmfscore from '../../images/pmfscore.png'

const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const url = 'https://earkick.us1.list-manage.com/subscribe/post?u=98e0d82df47a599d9c5114e5e&amp;id=b3a5ca70c4'

const thankYouCard = () => {
  return (
    <div className="thank-you-card">
      <div className="thank-you-content">
        <h4>Thank you!</h4>
        <p>
          We have received your information and will follow up with you within
          one business day.
        </p>
      </div>
    </div>
  )
}

const C = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [validationError, setValidationError] = useState(false)
  const [subscribeStatus, setSubscribeStatus] = useState(false)

  const processForm = async (subscribe) => {
    setSubscribeStatus(false)
    setValidationError(false)

    if (!emailTest.test(email)) {
      setValidationError('Please enter valid email address')
      return
    }

    setSubscribeStatus('sending')
    const response = await subscribe({
      EMAIL: email
    })

    console.log(response)
  }

  return (
    <div className="landing-page">
      <Container>
        <Row className="header">
          <Col>
            <img src={logo} alt='logo' className='logo' />
          </Col>
          <Col className="login-box">
            <Button onClick={() => navigate('/login')}>Login</Button>
          </Col>
        </Row>
        <Row className="highlights">

          <Col className="hero-cols content">
            <Row>
              <Col>
                <h1>Find product market fit
                    and bring your startup
                    to success
                </h1>
                <p>Identify your high-expectation customer,
      find out what to build for them and what to
      double-down on.</p>
              </Col>
            </Row>
            <Row className="request-form">
              <Col>
                {subscribeStatus === 'success' && thankYouCard()}
                {validationError && <p className="error">Please enter a valid email address</p>}
                  <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message, ...other }) => {
                      return (
                        <>
                          {status === 'success' && thankYouCard()}
                          {status !== 'success' && (
                            <div className="form-container">
                              <Input placeholder="Your work email" onChange={(e) => setEmail(e.target.value)} />
                              <Button disabled={status === 'sending'} onClick={() => processForm(subscribe)}>Request access</Button>
                            </div>
                          )}
                        </>
                      )
                    }}
                  />
              </Col>
            </Row>
          </Col>

          <Col className="hero-cols">
            <img src={pmfscore} alt='' className="hero-image" />
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default C
