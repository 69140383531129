import { queryRun } from './query'

const audienceAllGet = async (accountId) => {
  const query = 'SELECT audience, count(*) AS count FROM `feedback` GROUP BY audience ORDER BY count DESC'
  const { results, error } = await queryRun(query, accountId)

  if (error) {
    throw Error('Error while loading audience data')
  }

  return results
}

export {
  audienceAllGet
}
