import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { t } from '../../../utils/translate'

const FormStepTextinput = ({ form, placeholder, onSelect, onKeyPress }) => {
  const [value, setValue] = useState(null)
  const inputReference = useRef(null)

  useEffect(() => {
    if (inputReference.current) {
      inputReference.current.focus()
    }
  }, [])

  return (
    <div className="step-inputs">
      <Row>
        <style type="text/css">
        input {'{'}
          &::placeholder {'{'}
            {`color: ${form.metadata.colors.brandLight} !important;`}
            {'}'}
          {'}'}
        </style>
        <Col>
          <input
            style={{
              backgroundColor: form.metadata.colors.background,
              color: form.metadata.colors.brand,
              borderColor: form.metadata.colors.brandLight
            }}
            ref={inputReference}
            autoFocus
            placeholder={t(placeholder)}
            type="text"
            onKeyUp={(e) => {
              setValue(e.target.value)
              onKeyPress(e.target.value)
            }}
            onChange={(e) => {
              setValue(e.target.value)
              onKeyPress(e.target.value)
            }}
          />

        </Col>
        <Row>
          <Col>
            <Button
              style={{
                borderColor: form.metadata.colors.brand,
                backgroundColor: form.metadata.colors.brandLight,
                color: form.metadata.colors.brand
              }}
              onClick={() => onSelect(value)}>{t('ok')}</Button>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default FormStepTextinput
