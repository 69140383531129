import React from 'react'
import { Col, Row } from 'reactstrap'
import Parser from 'html-react-parser'
import Audience from './audience'
import Options from './options'
import Dropdown from './dropdown'
import Textinput from './textinput'
import Textarea from './textarea'
import ThankYou from './thankyou'
import { t } from '../../../utils/translate'

const FormStep = ({ form, audiences, accountName, processId, step, nextStep, results, setResult, submitResults, languages }) => {
  const [result] = step ? results.filter(r => r.name === step.name) : [null]

  return (
    <div className="step">
      {step && step.question &&
        <>
          <Row>
            <Col className="step-heading">
              <h1>{Parser(t(step.question, { accountName }))}</h1>
              {step.description && <p>{Parser(t(step.description))}</p>}
            </Col>
          </Row>
        </>
      }
      {step && step.type === 'options' &&
        <Options
          form={form}
          key={step.name}
          options={step.options}
          onSelect={option => {
            setResult(step, option)
            submitResults()
            nextStep()
          }}
          defaultValue={result && result.value}
        ></Options>
      }
      {step && step.type === 'audience' &&
        <Audience
          form={form}
          key={step.name}
          audiences={audiences}
          onSelect={option => {
            setResult(step, option)
            submitResults()
            nextStep()
          }}
          onKeyPress={value => { setResult(step, value) }}
          defaultValue={result && result.value}
          languages={languages}
        ></Audience>
      }
      {step && step.type === 'dropdown' &&
        <Dropdown
          form={form}
          key={step.name}
          options={step.options}
          onSelect={option => {
            setResult(step, option)
            submitResults()
            nextStep()
          }}
          defaultValue={result && result.value}
        ></Dropdown>
      }
      {step && step.type === 'textinput' &&
        <Textinput
          form={form}
          key={step.name}
          placeholder={step.placeholder}
          onKeyPress={value => { setResult(step, value) }}
          onSelect={option => {
            setResult(step, option)
            submitResults()
            nextStep()
          }}
          step={step}
        ></Textinput>
      }
      {step && step.type === 'textarea' &&
        <Textarea
          form={form}
          key={step.name}
          placeholder={step.placeholder}
          onKeyPress={value => { setResult(step, value) }}
          onSelect={option => {
            setResult(step, option)
            submitResults()
            nextStep()
          }}
          step={step}
          results={results}
        ></Textarea>
      }
      {!step &&
        <ThankYou
          title={form.metadata.thankyou.title}
          subtitle={form.metadata.thankyou.subtitle}
          form={form}
          results={results}
          processId={processId}
        ></ThankYou>
      }
    </div>
  )
}

export default FormStep
