import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { backgroundColorPlugin } from './chart-utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    backgroundColorPlugin: {
      color: 'transparent'
    }
  },
  elements: {
    point: {
      radius: 0,
      borderWidth: 0
    }
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        callback: function (val, index) {
          if (index % 2 === 0) return ''

          const date = this.getLabelForValue(val)
          return (new Date(date))
            .toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
            .replace('2015', '15')
            .replace('2016', '16')
            .replace('2017', '17')
            .replace('2018', '18')
            .replace('2019', '19')
            .replace('2020', '20')
            .replace('2021', '21')
            .replace('2022', '22')
            .replace('2023', '23')
            .replace('2024', '24')
            .replace('2025', '25')
            .replace('2026', '26')
        }
      }
    },
    y: {
      grid: {
        color: '#313537'
      },
      ticks: {
        callback: function (val, index) {
          const data = this.getLabelForValue(val)
          return `${data}%`
        },
        font: {
          size: 14
        }
      }
    }
  }
}

const C = ({ data, title }) => {
  const { labels } = data
  const datasets = [{
    data: data.values,
    backgroundColor: '#FDF38B',
    borderColor: '#FDF38B',
    cubicInterpolationMode: 'monotone',
    tension: 0.4
  }]

  return (
    <div className="showcase-chart">
      <h3>{ title }</h3>
      <div className="chart-area">
        <Line plugins={[backgroundColorPlugin]} data={{ labels, datasets }} options={options} />
      </div>
    </div>
  )
}

export default C
