import { functions, httpsCallable } from './firebase'

const translate = async (keys, language) => {
  const call = httpsCallable(functions, 'translateDeepl')
  const { data } = await call({ keys, language })
  return data
}

export {
  translate
}
