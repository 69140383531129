import React, { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet } from 'react-router'
import { checkUser } from '../../core/reducers/user'
import Loader from '../../components/Loader'
import './index.scss'

const FormLayout = memo(() => {
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.rootReducers.isLoading)
  useEffect(() => {
    dispatch(checkUser())
  }, [dispatch])

  return (
    <>
      <div className='form-layout'>
        {
          isLoading ? (<Loader />) : (<Outlet />)
        }
      </div>
    </>
  )
})

FormLayout.displayName = 'FormLayout'

export default FormLayout
