import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Input, Label } from 'reactstrap'
import { cacheGetItemData, cacheSetItem } from '../../api/cache'
import { queryRun } from '../../api/query'
import { setAudience, setAudienceAll } from '../../core/reducers/home-control'
import HomeSection from './home-section'
import { audienceAllGet } from '../../api/audience'

const showRevenue = false

const audienceScoreQuery = 'SELECT * FROM ( SELECT feel, COUNT(*) as count FROM `feedback` WHERE audience = \'OCCUPATION\' GROUP BY feel ) AS test ORDER BY count DESC'
const revenueByAudience = 'SELECT SUM(revenue.revenue) AS revenue, IFNULL(CAST(feel.audience AS STRING), "unknown") AS audience FROM ( SELECT user_id, SUM(value) AS revenue FROM `REVENUE_TABLE` WHERE user_id IS NOT NULL GROUP BY user_id ) as revenue LEFT JOIN `feedback` AS feel ON feel.user_id = revenue.user_id  GROUP BY feel.audience'

const HomeAudience = () => {
  const dispatch = useDispatch()
  const referenceScore = useSelector((state) => state.homeControl.audienceScore)

  const account = useSelector(state => state.accountReducers.account)
  const selectedAudience = useSelector((state) => state.homeControl.audience)
  const audienceAll = useSelector((state) => state.homeControl.audienceAll)
  const [audienceResults, setAudienceResults] = useState(cacheGetItemData('audience-results') || false)
  const [audienceScore, setAudienceScore] = useState([]) // [{ audience_ai: score }
  const [loading, setLoading] = useState(!cacheGetItemData('audience-results'))
  const [dataError, setError] = useState(null)
  const [cacheIndex, setCacheIndex] = useState(account.metadata.submissionsCount)
  const [cacheKeys, setCacheKeys] = useState([])
  const [revenue, setRevenue] = useState(null)
  const [revenueKey, setRevenueKey] = useState(0)

  const getRevenue = async () => {
    setLoading(true)
    // no-multi-str
    try {
      const { results } = await queryRun(revenueByAudience, account.id, true)
      console.log(results)
      setRevenue(results)
      setRevenueKey(1)
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  const calculateScoreForEachAudience = async (results) => {
    const keys = ['audience-results']
    for (let i = 0; i < results.length; i++) {
      const cacheKey = `audience-score-${results[i].audience}`
      keys.push(cacheKey)
      const cacheValue = cacheGetItemData(cacheKey)

      if (cacheValue !== null) {
        setAudienceScore(audienceScore => [...audienceScore, { audience: results[i].audience, score: cacheValue }])
      } else {
        const { results: audienceResults, error: audienceError } = await queryRun(audienceScoreQuery.replace('OCCUPATION', results[i].audience), account.id)

        if (audienceError) {
          console.log(audienceResults, audienceError)
        }

        const all = audienceResults.reduce((acc, row) => acc + row.count, 0)
        const scoreValue = (_.find(audienceResults, { feel: 'Very disappointed' })?.count || 0) / all * 100 || 0
        cacheSetItem(cacheKey, scoreValue)
        setAudienceScore(audienceScore => [...audienceScore, { audience: results[i].audience, score: scoreValue }])
      }
    }

    setCacheKeys(keys)
  }

  const run = async () => {
    setLoading(true)
    getRevenue()

    try {
      const results = await audienceAllGet(account.id)
      console.log(results)
      const allAudience = _.map(results, 'audience')
      dispatch(setAudienceAll(allAudience))
      dispatch(setAudience(allAudience))

      setAudienceResults(results)
      setLoading(false)
      cacheSetItem('audience-results', results)

      calculateScoreForEachAudience(results)
    } catch (error) {
      console.log(error)
      setError(error.message)
    }
  }

  useEffect(() => {
    if (account.metadata.submissionsCount !== cacheIndex) {
      setCacheIndex(account.metadata.submissionsCount)
      setLoading(true)
      cacheKeys.forEach(key => cacheSetItem(key, false))
      setAudienceResults(false)
      run()
    }
  }, [cacheIndex, account])

  useEffect(() => {
    if (audienceResults === false) {
      run()
    } else {
      calculateScoreForEachAudience(audienceResults)
    }
  }, [])

  const getAudienceScore = (audience) => {
    const element = _.find(audienceScore, { audience })

    if (!element) {
      return 'Loading...'
    }

    return element.score.toFixed(2)
  }

  const getAudienceRevenue = (audience) => {
    if (!revenue) {
      return ''
    }

    const audienceRevenue = _.find(revenue, { audience })

    if (!audienceRevenue) {
      return ''
    }

    return Math.round(audienceRevenue.revenue)
  }

  const getAudienceRevenuePercent = (audience) => {
    if (!revenue) {
      return ''
    }

    const audienceRevenue = getAudienceRevenue(audience)

    if (!audienceRevenue) {
      return ''
    }

    const all = _.filter(revenue, r => r.audience !== 'unknown').reduce((acc, row) => acc + row.revenue, 0)

    return Math.round(100 * audienceRevenue / all) + '%'
  }

  const getAudienceScoreClassName = (audience) => {
    const score = getAudienceScore(audience)

    if (score === 'Loading...') {
      return 'loading'
    }

    if (score > referenceScore) {
      return 'good-score'
    }

    return 'bad-score'
  }

  const clickedAudience = (audience) => () => {
    dispatch(setAudience([audience]))
  }

  const selectedAudienceHandle = (audience, checked) => {
    if (checked) {
      dispatch(setAudience(_.uniq([...selectedAudience, audience])))
    } else {
      dispatch(setAudience(_.filter(selectedAudience, (o) => o !== audience)))
    }
  }

  const toggleAudience = (checked) => {
    if (checked) {
      dispatch(setAudience(audienceAll))
    } else {
      dispatch(setAudience([]))
    }
  }

  return (<HomeSection name="audience">
    <h2>Audience</h2>
    {dataError && <p className="error">{dataError}</p>}
    {loading && <p>Loading...</p>}
    {!dataError && !loading && audienceResults &&
      <>
        <Label className="toogle-audience">
          Toggle all
          <Input
            checked={selectedAudience.length === audienceAll.length}
            type="checkbox"
            onChange={(e) => toggleAudience(e.target.checked)}
            ></Input>
        </Label>
        <div className="audiences-container">
          {audienceResults.map(({ audience, count }) => (
            <div
              key={`${audience}-${revenueKey}`}
              className={`audiences ${getAudienceScoreClassName(audience)}`}
              onClick={clickedAudience(audience)}
            >
              <div className="audience-item audience">{audience}</div>
              <div className="audience-item count" title="Count">{count}</div>
              <div className="audience-item score" title="Score">{getAudienceScore(audience)}</div>
              {showRevenue &&
                <div className="audience-item revenue" title={`Revenue: $${getAudienceRevenue(audience)}`}>{getAudienceRevenuePercent(audience)}</div>
              }
              <div className="audience-item select-audience">
                <Input
                  checked={selectedAudience.includes(audience)}
                  type="checkbox"
                  onChange={(e) => selectedAudienceHandle(audience, e.target.checked)}
                  ></Input>
              </div>
            </div>
          ))}
        </div>
      </>
    }

  </HomeSection>)
}

export default HomeAudience
