import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Table } from 'reactstrap'
import './results-table.scss'

const ResultsTable = ({ results }) => {
  const audienceAll = useSelector((state) => state.homeControl.audienceAll)
  const navigate = useNavigate()
  const [keys, setKeys] = useState([])
  const [extras, setExtras] = useState([])

  useEffect(() => {
    setKeys(_.without(Object.keys(results[0]), 'id'))
  }, [results])

  const validAudience = (audience) => {
    return _.indexOf(audienceAll, audience) > -1
  }

  const show = (id) => {
    setExtras(_.xor(extras, [id]))
  }

  return (
    <Table className="results-table">
      {results.length > 0 &&
      <>
        <thead>
          <tr>
            {_.difference(keys, ['occupation_ai', 'occupation', 'email', 'user_id', 'date', 'user_agent', 'partial_submission']).map((key, i) => (
              <th key={i}>{key}</th>
            ))}
          </tr>
          </thead>
        <tbody>
          {results.map((row, i) => (
            <Fragment key={`af-${i}`}>
              <tr key={`a-${i}`} className={`clickable-row ${i % 2 ? 'odd' : 'even'} ${_.indexOf(extras, `b-${i}`) > -1 ? 'top-opened' : 'top-closed'}`} onClick={() => show(`b-${i}`)}>
              {_.difference(keys, ['occupation_ai', 'occupation', 'email', 'user_id', 'date', 'user_agent']).map((key, j) => (
                <td key={`aa-${i}-${j}`}>
                  {key === 'date' &&
                    <>{(new Date(row[key].value)).toLocaleDateString()}</>
                  }
                  {key !== 'date' &&
                    <>{row[key]}</>
                  }
                  {key === 'audience' && (!validAudience(row[key]) || row[key] === '') &&
                    <button onClick={() => navigate(`/dashboard/fix-audience/${row.id}`)}>Fix</button>
                  }
                </td>
              ))}
              </tr>
              <tr key={`b-${i}`} className={` ${i % 2 ? 'odd' : 'even'} ${_.indexOf(extras, `b-${i}`) > -1 ? 'bottom-opened' : 'bottom-closed'}`}>
              {['date', 'email', 'user_id', 'user_agent', 'partial_submission'].map((key, j) => (
                <Fragment key={`cf-${i}-${j}`}>
                  {_.indexOf(extras, `b-${i}`) > -1 &&
                    <td key={`ba-${i}-${j}`}>
                      <strong>{key}:</strong><br />
                      {key === 'date' &&
                        <>{(new Date(row[key].value)).toLocaleDateString()}</>
                      }
                      {key !== 'date' &&
                        <><span>{row[key]}</span></>
                      }
                      {key === 'partial_submission' &&
                        <>{row[key] ? 'true' : 'false'}</>
                      }
                    </td>
                  }
                </Fragment>
              ))}
            </tr>
          </Fragment>
          ))}
        </tbody>
      </>
      }
    </Table>
  )
}

export default ResultsTable
