import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Button, Col, Container, Row } from 'reactstrap'
import StepCounter from '../../components/step-counter'
import AudienceManager from '../audience-manager'
import { useSelector } from 'react-redux'
import { accountSubscribe } from '../../api/accounts'
import './index.scss'

const OnboardingAudience = () => {
  const navigate = useNavigate()
  const [audienceList, setAudienceList] = useState([])
  const account = useSelector(state => state.accountReducers.account)

  useEffect(() => {
    let unsubscribe = () => {}

    if (account) {
      unsubscribe = accountSubscribe(account.id, subscribedData => {
        setAudienceList(subscribedData.metadata.audience || [])
      })
    }

    return unsubscribe
  }, [account])

  return <Container className="onboarding">
    <Row>
      <center>
        <StepCounter step={3} steps={5} />
      </center>
    </Row>
    <Row>
      <h1>Define your audience</h1>
      <p>Do you have already an idea of your different audience segments? Your users will be able to select from the options you define here. If you don’t know your audience segments yet, just skip this step. This will be visible to your users.</p>
    </Row>
    <Row>
      <Col></Col>
        <Col>
          <AudienceManager
            hideSaveButton={true}
          />
        </Col>
      <Col></Col>
    </Row>
    <Row>
      <Col></Col>
      <Col>
        <Row>
          <center>
            <Button
              color="primary"
              onClick={() => navigate('/onboarding/form')}
              >{audienceList.length === 0 ? <>Skip</> : <>Next</>}</Button>
            </center>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  </Container>
}

export default OnboardingAudience
