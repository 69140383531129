import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Button, Col, Container, Input, Label, Row } from 'reactstrap'
import Loader from '../../components/Loader'
import StepCounter from '../../components/step-counter'
import { accountCreateByUser } from '../../api/accounts'
import { useSelector, useDispatch } from 'react-redux'
import { checkUser } from '../../core/reducers/user'

const C = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state) => state.userReducers.user)
  const [project, setProject] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (user && user.exist) {
      console.log('Redirecting from project to audience')
      console.log(user)
      navigate('/onboarding/audience')
    }
  }, [user])

  const createProject = async () => {
    if (project === '') {
      setError('Project name is required')
    }

    setLoading(true)

    const response = await accountCreateByUser(project)

    if (response.error) {
      setError(response.errorMessage)
      setLoading(false)
      return
    }

    dispatch(checkUser()) // reload user data in state
    navigate('/onboarding/audience')
  }

  useEffect(() => {
    setError(false)
  }, [project])

  return <Container className="onboarding">
    <Row>
      <center>
        <StepCounter step={2} steps={5} />
      </center>
    </Row>

    <Row>
      <h1>Project name</h1>
      <p>Define the project name. What&apos;s the name of your web or mobile app that your users identify you with? This will be visible to your users.</p>
    </Row>

    <Row>
      <Col></Col>
      <Col>
        {error &&
          <p className="error">{error}</p>
        }
        <Row>
          <Label>Enter project name</Label>
          <br />
          <Input type="text" name="email" placeholder="e.g. Pinterest" onChange={(e) => setProject(e.target.value)} />
        </Row>

        <Row>
          {!loading &&
            <center>
              <Button color="primary" onClick={() => createProject()}>Next</Button>
            </center>
          }
          {loading &&
            <Loader />
          }
        </Row>
      </Col>
      <Col></Col>
    </Row>
  </Container>
}

export default C
